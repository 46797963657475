import { isArguments } from "lodash";
import React, { useEffect, useState } from "react";
import FormBlock1 from "./vcardforms/FormBlock1";
import FormBlock2 from "./vcardforms/FormBlock2";
import FormBlock3 from "./vcardforms/FormBlock3";
import FormBlock4 from "./vcardforms/FormBlock4";
import FormBlock5 from "./vcardforms/FormBlock5";

const VirtualCard = (props) => {
  const { editarOpt, user, setReloadCompanies, stepForm, stepFormChange } = props;
  const localDataAgent = localStorage.getItem("dataagent") ? JSON.parse(localStorage.getItem("dataagent")) : [];
  //console.log("Size data view",localDataAgent);

  //const [stepFormComp, stepFormCompChange] = useState(editarOpt);
  //console.log("from App js ",props);

  const backForm = (e) => {
    e.preventDefault();
    stepFormChange(stepForm - 1);
  };
  const styleProgress = { backgroundColor: "rgb(21,89,228)", borderRadius: "5px", height: "10px", position: "relative", width: stepForm === 0 || stepForm === 5 ? 0 : (100 * stepForm) / 5 + "%" };
  /*
  useEffect(() => {
    console.log("valor de editar opcion", editarOpt);
  });
*/
  //if (localDataAgent.iscomplete === false) {
  return (
    <>
      <div id="modal1" className="modal">
        <div className="modal-content">
          <div className="row">
            <div className="flex-btw">
              <div>
                {stepForm === 1 || stepForm === 5 ? (
                  ""
                ) : (
                  <a href="" onClick={backForm}>
                    <span className="material-icons circ-blue z-depth-2">chevron_left</span>
                  </a>
                )}
              </div>
              <div>
                <h4 className="st text-center">Mi tarjeta virtual</h4>
              </div>
              <div>
                {stepForm === 0 || stepForm === 5 ? (
                  ""
                ) : (
                  <span className="pointer modal-close">
                    <span className="material-icons circ-blue z-depth-2">close</span>
                  </span>
                )}
              </div>
            </div>

            <div className="prog-bar mb-4">
              <div style={styleProgress}></div>
            </div>
            {/* Establecer las condiciones de los formularios */}

            {stepForm == 0 || stepForm == 1 ? (
              <FormBlock1 stepFormChange={stepFormChange}></FormBlock1>
            ) : stepForm == 2 ? (
              <FormBlock2 stepFormChange={stepFormChange} user={user}></FormBlock2>
            ) : stepForm == 3 ? (
              <FormBlock3 stepFormChange={stepFormChange}></FormBlock3>
            ) : stepForm == 4 ? (
              <FormBlock4 stepFormChange={stepFormChange}></FormBlock4>
            ) : stepForm == 5 ? (
              <FormBlock5 stepFormChange={stepFormChange} setReloadCompanies={setReloadCompanies}></FormBlock5>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
  /* } else {
    return (
      <>
        <div id="modal1" className="modal">
          <div className="modal-content">
            <div className="row">
              <div className="flex-btw">
                <div>
                  {stepForm === 1 || stepForm === 5 ? (
                    ""
                  ) : (
                    <a href="" onClick={backForm}>
                      <span className="material-icons circ-blue z-depth-2">chevron_left</span>
                    </a>
                  )}
                </div>
                <div>
                  <h4 className="st text-center">Mi tarjeta virtual completado</h4>
                </div>
                <div>
                  {stepForm === 0 || stepForm === 5 ? (
                    ""
                  ) : (
                    <a href="#" className="modal-close">
                      <span className="material-icons circ-blue z-depth-2">close</span>
                    </a>
                  )}
                </div>
              </div>


              {editarOpt == 1 ? (
                <FormBlock1 stepFormChange={stepFormChange}></FormBlock1>
              ) : editarOpt == 2 ? (
                <FormBlock2 stepFormChange={stepFormChange} user={user}></FormBlock2>
              ) : editarOpt == 3 ? (
                <FormBlock3 stepFormChange={stepFormChange}></FormBlock3>
              ) : editarOpt == 4 ? (
                <FormBlock4 stepFormChange={stepFormChange}></FormBlock4>
              ) : editarOpt == 5 ? (
                <FormBlock5 stepFormChange={stepFormChange} setReloadCompanies={setReloadCompanies}></FormBlock5>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
  */
};
export default VirtualCard;
