import React from "react";

export default function PremiumDetail() {
  return (
    <div id="modal-premium-detail" className="modal">
      <div className="modal-content">
        <a href="#" className="modal-close top-left">
          Cerrar
        </a>
        <h5 className="st text-center">Suscríbete a Click+ Premium</h5>

        <table className="premium-tbl tableFixHead">
          <thead>
            <tr>
              <th className="no-border"></th>
              <th>
                <p>Plan gratuito</p>
                <p className="txt-red small-txt">Continuar con este plan</p>
              </th>
              <th>
                <p>Plan premium</p>
                <p className="back-red small-txt">Contrata por $100 semanales</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="txt-tbl st">Tarjeta virtual</td>
              <td>
                <span className="material-icons">done</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
            <tr>
              <td>Link a redes sociales</td>
              <td>
                <span className="material-icons">done</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
            <tr>
              <td>Espacio en correo electronico institucional @clickseguros.online</td>
              <td>5Gb</td>
              <td>25Gb</td>
            </tr>
            <tr>
              <td>Whatsapp web</td>
              <td>
                <span className="material-icons">clear</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
            <tr>
              <td>Tarjeta virtual</td>
              <td>
                <span className="material-icons">done</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
            <tr>
              <td className="st">Sitio público</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>Páginas públicas</td>
              <td>1</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Estilos</td>
              <td>4</td>
              <td>8</td>
            </tr>
            <tr>
              <td>Imágenes por ramo</td>
              <td>5</td>
              <td>10</td>
            </tr>
            <tr>
              <td>Logo propio</td>
              <td>
                <span className="material-icons">done</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
            <tr>
              <td>Foto personal</td>
              <td>
                <span className="material-icons">done</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
            <tr>
              <td>Selección de aseguradoras</td>
              <td>
                <span className="material-icons">done</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
            <tr>
              <td>Selección de ramos</td>
              <td>
                <span className="material-icons">done</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
            <tr>
              <td className="st">Solicitud de cotización</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>Vehículos</td>
              <td>
                <span className="material-icons">done</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
            <tr>
              <td>Gastos médicos</td>
              <td>
                <span className="material-icons">done</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
            <tr>
              <td>Hogar</td>
              <td>
                <span className="material-icons">done</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
            <tr>
              <td>Otros ramos</td>
              <td>
                <span className="material-icons">done</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
            <tr>
              <td>Vida</td>
              <td>
                <span className="material-icons">clear</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
            <tr>
              <td className="st">Gestión de leads</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>Básico</td>
              <td>
                <span className="material-icons">done</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
            <tr>
              <td>Avanzado</td>
              <td>
                <span className="material-icons">clear</span>
              </td>
              <td>Próximamente</td>
            </tr>
            <tr>
              <td>Agrega los leads a tu agenda de contactos</td>
              <td>
                <span className="material-icons">done</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
            <tr>
              <td className="st">Cotizadores online</td>
              <td>&nbsp;</td>
              <td>Próximamente</td>
            </tr>
            <tr>
              <td>Vehículos</td>
              <td>
                <span className="material-icons">clear</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
            <tr>
              <td>Hogar</td>
              <td>
                <span className="material-icons">clear</span>
              </td>
              <td>
                <span className="material-icons">done</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
