import React, { useState, useEffect, useContext } from "react";
import { ContextGlobal } from "../../../contexts/ContextGlobal";
import { getUserAuthToken } from "../../../api/token";
import useAuth from "../../../hooks/useAuth";
import { useLocation } from "react-router";
import db from "../../../firebase/firebaseConfig";
import { collection, addDoc, onSnapshot, doc, updateDoc, where, query, getDocs } from "firebase/firestore";

const FormBlock1 = (props) => {
  const { setStepCard, setDataFirebase, dataAgnFb } = useContext(ContextGlobal);
  const { user } = useAuth();
  const [agenturl, changeAgentUrl] = useState("");
  const [agentname, cambiarAgentName] = useState("");
  const [isavailable, changeAvailable] = useState(false);
  const [validateInfo, setValidateInfo] = useState(false);
  const [checkInfo, setCheckInfo] = useState(false);
  const [hasDomain, setHasDomain] = useState(false);
  const localDataAgent = localStorage.getItem("dataagent") ? JSON.parse(localStorage.getItem("dataagent")) : [];
  const [dataAgent, changeDataAgent] = useState(localDataAgent);
  //console.log(user);

  const userToken = getUserAuthToken();

  useEffect(() => {
    //Cada vez que hay un cambio seteamos los valores
    localStorage.setItem("dataagent", JSON.stringify(dataAgent));
    //Es hora de obtener la información de firebase
  }, [dataAgent]);



  /* when document in localstorage send to firebase*/
  useEffect(() => {
    //console.log("Ya no se valida con el storage :::", dataAgnFb);
    //console.log("data storage:", localDataAgent);
    console.log("::::::::::::::::::::::::::::::::: Formvblock 1", localDataAgent.document);
    if (localDataAgent.document != undefined && localDataAgent.document != null) {
      const unsub = onSnapshot(doc(db, "cp_agents", localDataAgent.document), (doc) => {
        console.log("Current data: ", doc.data());
        const datatmp = doc.data();
        changeAgentUrl(datatmp?.agent_url);
        cambiarAgentName(datatmp?.agent_name);
      });
    } else {
      //window.location.reload();

    }
  }, []);

  const handleInput = (e) => {
    if (e.target.checked) {
      setValidateInfo(true);
    } else {
      setValidateInfo(false);
    }
  };

  useEffect(() => {
    if (isavailable == true && validateInfo == true) {
      setCheckInfo(true);
    } else {
      setCheckInfo(false);
    }
  }, [isavailable, validateInfo]);

  /* Domain validation */
  useEffect(() => {
    if (agenturl.length > 3 && !hasDomain) {
      const q = query(collection(db, "cp_agents"), where("agent_url", "==", agenturl));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.empty) {
          console.log("Este nombre si esta disponible");
          changeAvailable(true);
        } else {
          // console.log("No esta disponible el nombre");
          changeAvailable(false);
        }
      });
    } else {
      changeAvailable(true);
    }
  }, [agenturl, hasDomain]);
  /* End domain validation */

  const validateURL = (namestr) => { };

  const timeStamp = () => {
    let currentDateTime = Date.now();
    let parseDate = new Intl.DateTimeFormat('es-MX', { dateStyle: 'short', timeStyle: 'medium' }).format(currentDateTime);
    return parseDate;
  }

  const sendForm = (e) => {
    e.preventDefault();
    //const input = e.target.children[0]
    //console.log('Step selection', input.value);
    if (localDataAgent.length === 0) {
      console.log("Creando el registro...");
      try {
        console.log("nombre:", agentname);
        console.log("direccion:", agenturl);

        const res = addDoc(collection(db, "cp_agents"), {
          IdVend: user.IdVend,
          Clave: user.Clave,
          token_url: userToken,
          step: 2,
          iscomplete: false,
          agent_name: agentname,
          agent_url: agenturl,
          has_domain: true,
          leads_counter: 0,
          redes_agente: [{}],
          created_at: timeStamp()
        })
          .then((response) => {
            console.log("Document written with ID: ", response.id);
            //if this all correct, next step
            changeDataAgent({
              document: response.id,
              step: 2,
              iscomplete: false,
            });

            setStepCard(2, false); //Setting in the main view
            setDataFirebase({
              //document: response.id,
              IdVend: user.IdVend,
              Clave: user.Clave,
              token_url: userToken,
              step: 2,
              iscomplete: false,
              agent_name: agentname,
              agent_url: agenturl,
              has_domain: true,
              leads_counter: 0,
              redes_agente: [{}],
            }); //SET THE PARAMAS
            props.stepFormChange(2);
          })
          .catch((error) => {
            console.log(error);
          });

        //console.log(res);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    } else {
      console.log("Actualizando el registro...", localDataAgent.document);
      //Si existen datos solo actualizamos

      const userRef = doc(db, "cp_agents", localDataAgent.document);
      updateDoc(userRef, {
        step: 2,
        iscomplete: false,
        agent_name: agentname,
        agent_url: agenturl,
      })
        .then(() => {
          //console.log("Se ha actualizado la informacion");

          //Actualizamos la información
          localStorage.setItem(
            "dataagent",
            JSON.stringify({
              ...dataAgent,
              step: 2,
              /* Segunda validacion del formulario con el storage */
              iscomplete: localStorage.iscomplete, //FALSO
            })
          );
          setStepCard(2, false); //Setting in the main view
          props.stepFormChange(2);
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    }
  };

  useEffect(() => {
    const q = query(collection(db, "cp_agents"), where("agent_url", "==", agenturl));
    //const q = query(collection(db, "cp_agents"), where("token_url", "==", localDataAgent.token_url));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.empty) {
      } else {
        const dataFirebase = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        if (dataFirebase[0].has_domain == true) {
          setHasDomain(true);
        } else {
          setHasDomain(false);
        }
      }
    });
  }, [agenturl, hasDomain]);

  const removespaces = (str) => {
    //return str.replace(/\s/g, "");
    let lowerstr = str.toLowerCase();
    let nospace = lowerstr.replace(/\s/g, "");
    let nonumbers = nospace.replace(/[^A-Za-z]+/g, "");
    return nonumbers;
  };

  //console.log(hasDomain);

  return (
    <div className="col m12 s12">
      <h5 className="st text-center">Información personal</h5>
      <p className="dt-sp">
        Para comenzar indica el nombre que utilizarás para tu tarjeta virtual, portal y mail. Te recomendamos que sea corto y facil de recordar ya que no podrás cambiarlo después
      </p>
      <div className="rt-sp">
        <form action="" onSubmit={sendForm}>
          <input type="hidden" name="stepinput" value="2" readOnly />
          <div className="flex-alw mt-4 mb-3">
            <div className="input-field col m12 s12">
              <input
                id="agent_url_address"
                type="text"
                value={agenturl}
                placeholder="Sin espacios, mínimo 4 caracteres, máximo 16 caracteres"
                onChange={(e) => changeAgentUrl(removespaces(e.target.value))}
                maxLength="16"
                required={true}
                disabled={hasDomain}
              />
              <label htmlFor="agent_url_address" className="active">
                Nombre de dominio y mail de tu portal
              </label>
            </div>
            <div className="col m12 s12 ">
              {agenturl?.length > 3 ? (
                isavailable && !hasDomain ? (
                  <small className="green-text flex-alw">
                    <span className="material-icons mr-2">check_circle</span> Dominio disponible
                  </small>
                ) : !hasDomain ? (
                  <small className="red-text flex-alw">
                    <span className="material-icons mr-2">cancel</span> Dominio no disponible, intenta con otro.
                  </small>
                ) : (
                  <span>No es posible cambiar tu dominio</span>
                )
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="blue-card">
            <div>Tu tarjeta virtual quedaría así</div>
            <div className="flex-alw mt-2">
              <span className="material-icons i-grey mr-2">perm_contact_calendar</span>
              <div className="hyper">tarjeta.id/{agenturl}</div>
            </div>

            <div className="mt-3">Tu portal quedaría así</div>
            <div className="flex-alw mt-2">
              <span className="material-icons i-grey mr-2">language</span>
              <div className="hyper">{agenturl}.clickseguros.online</div>
            </div>

            <div className="mt-3">Tu mail quedaría así</div>
            <div className="flex-alw mt-2">
              <span className="material-icons i-grey mr-2">outgoing_mail</span>
              <div className="hyper">{agenturl}@clickseguros.online</div>
            </div>
          </div>
          <div className="flex-alw mt-4">
            <div className="input-field col m12 s12">
              <input id="agent_name" type="text" value={agentname} placeholder="Ej. Agente Mariano Rocha" onChange={(e) => cambiarAgentName(e.target.value)} />
              <label htmlFor="agent_name" className="active">
                ¿Qué nombre quieres que aparezca en tu tarjeta digital?
              </label>
            </div>
          </div>
          <div className="txt-blue mb-3">
            <label>
              <input name="group1" type="checkbox" checked={validateInfo === true ? "checked" : ""} onChange={(e) => handleInput(e)} />
              <span>Estoy seguro que quiero estas direcciones</span>
            </label>
          </div>

          <div className="input-field col m12 s12 text-center">
            <button type="submit" className="waves-effect sq-btn darken-1 white-text" id="buttonBlock1" disabled={!checkInfo}>
              Continuar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default FormBlock1;
