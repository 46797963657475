import React, { useState, useEffect } from "react";
import db from "../../../firebase/firebaseConfig";
//import { collection, addDoc, query, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { doc, onSnapshot } from "firebase/firestore";

const FormBlock5 = (props) => {
  const { setReloadCompanies } = props;
  const localDataAgent = localStorage.getItem("dataagent") ? JSON.parse(localStorage.getItem("dataagent")) : [];
  //console.log("From local storage - step 5", localDataAgent);
  const [agent_data, changeAgentData] = useState({
    agent_name: "",
    agent_url: "",
  }); //Obtiene todo los datos from Firebase

  useEffect(() => {
    onSnapshot(doc(db, "cp_agents", localDataAgent.document), (doc) => {
      //changeAgentData(unsub);
      //console.log("final data", doc.data());
      changeAgentData(doc.data());
    });
  }, []);

  return (
    <div className="col m12 s12">
      <img src="/images/click-vcard-finish.png" alt="card-finish" className="responsive-img" />
      <h5 className="st text-center">¡Terminamos!</h5>
      <p className="st">Tu sitio esta listo para ser compartido</p>
      <p>Utiliza este link para llegar a más clientes.</p>
      <br />
      <div className="text-center mt-2">
        <button className="rec-dk z-depth-3 text-center mb-4 flex-c" onClick={() => navigator.clipboard.writeText(`${agent_data.agent_url}.clickseguros.online`)}>
          {agent_data.agent_url}.clickseguros.online{" "}
          <span>
            {" "}
            <i className="material-icons push_right">content_copy</i>
          </span>
        </button>
      </div>
      <div className="input-field col m12 s12 text-center bt-sp mt-5">
        <button type="submit" className="modal-close waves-effect sq-btn darken-1 white-text" id="buttonBlock4" onClick={() => setReloadCompanies(true)}>
          Terminar
        </button>
      </div>
    </div>
  );
};

export default FormBlock5;
