import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import GetAllLeads from "./GetAllLeads";
import IsPremium from "./IsPremium";
import Catalog from "./Catalog";
import MenuLeads from "./MenuLeads";
import { Chart } from "react-google-charts";
import Companies from "./Companies";

export default function Admin() {
  const { auth, logout, user } = useAuth();

  const data = [
    ["MesYear", "Leads", "Cerrados"],
    ["Septiembre", 0, 0],
    ["Octubre", 1170, 460],
    ["Noviembre", 660, 300],
  ];

  const datab = [
    ["Mes", "Nuevos portales", "Cuenta premium"],
    ["Mayo", 0, 0],
    ["Junio", 9, 3],
    ["Julio", 8, 3],
    ["Agosto", 12, 1],
    ["Septiembre", 9, 5],
    ["Octubre", 5, 5],
    ["Noviembre", 8, 5],
  ];

  const UsersKind = [
    ["Premium", "Free"],
    ["Premium", 130],
    ["Free", 670],
  ];

  const options = {
    title: "Conversiones",
    hAxis: { titleTextStyle: { color: "#333" } },
    vAxis: { minValue: 0 },
    chartArea: { width: "80%", height: "80%" },
    animation: {
      duration: 1000,
      easing: "out",
      startup: true,
    },
    colors: ["#ff6259", "#e72800"],
    region: "019",
    magnifyingGlass: { enable: true, zoomFactor: 5.5 },
  };

  const optionsPie = {
    title: "Cuentas Premium vs Cuentas free",
    colors: ["#ff6259", "#e72800"],
    pieHole: 0.4,
    is3D: false,
    pieSliceText: "value",
    slices: {
      1: { offset: 0.2 },
    },
  };

  const places = [
    ["Country", "Visitantes"],
    ["Mexico", 9000],
    ["United States", 500],
    ["Brazil", 50],
    ["Canada", 200],
  ];

  const [menuBtn, menuSetState] = useState(false);

  useEffect(() => {}, [menuBtn]);
  return (
    <>
      <Switch>
        <Route exact path="/admin">
          <div className={!menuBtn ? "main-wrapper" : "main-wrapper small"}>
            <div className="cont-menu">
              <MenuLeads menuBtn={menuBtn} menuSetState={menuSetState} user={user} />
            </div>
            <div className="main-container">
              <div className="c2">
                <div className="card-click back-w z-depth-2 flex-c">
                  <div>
                    <h1 className="text-center biggest">6</h1>
                    <div className="mt-5">Número total de leads</div>
                  </div>
                </div>
              </div>
              <div className="c3">
                <div className="card-clickb back-w z-depth-2">
                  <h5 className="text-center">Conversiones de leads por mes</h5>
                  <div className="chart-cont">
                    <Chart chartType="AreaChart" width="100%" height="300px" data={data} options={options} />
                  </div>
                </div>
              </div>
              <div className="c2">
                <div className="card-clickb back-w z-depth-2">
                  <h5 className="text-center">Visitantes por país</h5>
                  <Chart
                    chartEvents={[
                      {
                        eventName: "select",
                        callback: ({ chartWrapper }) => {
                          const chart = chartWrapper.getChart();
                          const selection = chart.getSelection();
                          if (selection.length === 0) return;
                          const region = data[selection[0].row + 1];
                          console.log("Selected : " + region);
                        },
                      },
                    ]}
                    chartType="GeoChart"
                    width="100%"
                    height="400px"
                    data={places}
                    options={options}
                  />
                </div>
              </div>
              <div className="c3">
                <div className="card-clickb back-w z-depth-2 flex-c">
                  <div>
                    <h1 className="text-center biggest">2349</h1>
                    <div className="mt-5">Número total de visitas</div>
                  </div>
                </div>
              </div>
              <div className="c2">
                <div className="card-clickb back-w z-depth-2 flex-c">
                  <div>
                    <h1 className="text-center biggest">800</h1>
                    <div className="mt-5">Número total de portales activos</div>
                  </div>
                </div>
              </div>
              <div className="c3">
                <div className="card-clickb back-w z-depth-2">
                  <h5 className="text-center">Tipo de portales activos</h5>
                  <Chart chartType="PieChart" width="100%" height="400px" data={UsersKind} options={optionsPie} />
                </div>
              </div>

              <div className="c1">
                <div className="card-clickb back-w z-depth-2">
                  <div className="chart-cont">
                    <h5 className="text-center">Nuevos portales por mes</h5>
                    <Chart chartType="AreaChart" width="100%" height="300px" data={datab} options={options} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Route>
        <Route path="/admin/getleads">
          <div className={!menuBtn ? "main-wrapper" : "main-wrapper small"}>
            <div className="cont-menu">
              <MenuLeads menuBtn={menuBtn} menuSetState={menuSetState} user={user} />
            </div>
            <div className="main-container">
              <GetAllLeads />
            </div>
          </div>
        </Route>
        <Route path="/admin/ispremium">
          <div className={!menuBtn ? "main-wrapper" : "main-wrapper small"}>
            <div className="cont-menu">
              <MenuLeads menuBtn={menuBtn} menuSetState={menuSetState} user={user} />
            </div>
            <div className="main-container">
              <IsPremium />
            </div>
          </div>
        </Route>
        <Route path="/admin/catalogo">
          <div className={!menuBtn ? "main-wrapper" : "main-wrapper small"}>
            <div className="cont-menu">
              <MenuLeads menuBtn={menuBtn} menuSetState={menuSetState} user={user} />
            </div>
            <div className="main-container">
              <Catalog />
            </div>
          </div>
        </Route>
        <Route path="/admin/companies">
          <div className={!menuBtn ? "main-wrapper" : "main-wrapper small"}>
            <div className="cont-menu">
              <MenuLeads menuBtn={menuBtn} menuSetState={menuSetState} user={user} />
            </div>
            <div className="main-container">
              <Companies />
            </div>
          </div>
        </Route>
      </Switch>
    </>
  );
}
