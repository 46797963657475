import React from "react";

export default function () {
  return (
    <div id="modal-aviso" className="modal modal-fixed-footer">
      <div className="modal-content">
        <h4 className="modal-header mt-5">Aviso de privacidad</h4>

        <p>
          En cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás disposiciones aplicables, ponemos a disposición de los titulares de
          datos personales que obran en posesión de la persona moral CLICK SEGUROS INC, S.A. DE C.V., el siguiente:
        </p>
        <h3>AVISO DE PRIVACIDAD</h3>
        <p>
          CLICK SEGUROS INC, S.A. DE C.V. con domicilio en Avenida La Herradura No. 11, Col. Lomas del Huizachal, C.P. 53840, en el Estado de México, es una sociedad comprometida con la protección de
          sus datos personales, al ser responsable de su uso, manejo y confidencialidad; en tal virtud, es importante informarle que los datos personales que recabamos sobre Usted son necesarios para
          verificar y confirmar su identidad; administrar y operar los servicios y productos que solicita o contrata con nosotros, así como para cumplir con las obligaciones derivadas de la relación
          jurídica o de prestación de servicios existente entre Usted como titular de los datos personales y CLICK SEGUROS INC, S.A. DE C.V.
        </p>
        <p>
          Por ello, los datos personales que nos sean proporcionados, incluyendo los sensibles, que actualmente o en el futuro obren en posesión de nuestra sociedad, serán manejados, tratados y/o
          utilizados bajo los principios de licitud, consentimiento, calidad, información, proporcionalidad y responsabilidad, comprometiéndonos a observar y cumplir los citados principios, así como a
          no vender, alquilar, compartir o divulgar su información personal con fines ilícitos o contrarios a los fines para lo cual fueron proporcionados, salvo los casos excepcionales señalados en
          la Ley Federal de Protección de Datos Personales en Posesión de Particulares y su respectivo Reglamento.
        </p>
        <h3>¿PARA QUÉ FINES SE RECABA Y UTILIZAMOS SUS DATOS PERSONALES?</h3>
        <p>
          La información personal que recabamos es con la finalidad de elaborar los perfiles de nuestros clientes (contratantes, solicitantes, asegurados, beneficiarios y similares) a fin de
          ofrecerles y comercializar diversos productos y servicios de contratación de seguros de acuerdo a sus necesidades, así como analizar los riesgos y las circunstancias que en determinado
          momento se puedan llegar a producir ante cualquier contingencia, proveer los servicios y productos requeridos por usted; dar cumplimiento a obligaciones contraídas con los clientes; cotizar,
          solicitar o contratar los productos y servicios que ofrecemos; contacto; cobranza en cualquiera de sus fases de los productos contratados; inclusión en el catálogo de clientes; documentar la
          relación con los Clientes a través de la celebración de contratos y/o convenios correspondientes, y el cumplimiento de las obligaciones contenidas en nuestro contrato, convenio o de
          cualquier relación jurídica que establezcamos conforme a la Legislación aplicable vigente.; pues, dicha información nos permitirá brindarle un mejor servicio y elevar su calidad.{" "}
        </p>
        <p>
          Es por ello, y en virtud de que la actividad principal de nuestra empresa se encuentra relacionada con el ramo asegurador, que existen datos personales que resultan necesarios para que Usted
          pueda solicitar o contratar los productos y servicios que ofrecemos, tales como datos de identificación, domicilio, RFC, teléfono celular, correo electrónico, numero o folio de
          identificación oficial, datos de tarjetas bancarias, laborales, patrimoniales, financieros, académicos y sensibles (aquellos datos que puedan revelar aspectos como origen racial o étnico,
          estado de salud presente y futuro, información genética y biométrica, afiliación sindical, preferencia sexual, migratorios, características físicas y datos sobre procedimientos
          administrativos seguidos en forma de juicio y/o jurisdiccionales), asi como cualquier otro que sea necesario para verificar y confirmar su identidad, administrar y operar los servicios y
          productos que solicita o contrata con nosotros, así como para cumplir con las obligaciones derivadas de la relación jurídica o de prestación de servicios existente, los cuales se obtienen a
          partir de la información que su titular nos proporcione de manera personal, o bien, por cualquier medio electrónico, óptico, sonoro, visual, o a través de cualquier otra tecnología.
        </p>
        <p>
          Asimismo, los datos que Usted nos proporcione podrán ser utilizados con finalidades secundarias como mercadotecnia, publicidad y prospección comercial para el ofrecimiento de nuestros
          productos, servicios y promociones, medición de producción y/o fines estadísticos, asi como para evaluar la calidad del servicio, brindar un mejor servicio, realizar estudios internos sobre
          hábitos de consumo y enviar ofertas, promociones o publicidad relacionada con nuestros productos y servicios. En estos supuestos, podrán ser transferidos a las sociedades y/o instituciones
          que dependan directa o indirectamente de CLICK SEGUROS INC, S.A. DE C.V., siendo éstas entidades de carácter privado, tales como: aseguradoras, afianzadoras, profesionales médicos,
          hospitales, laboratorios, farmacias, empresas de asistencia y asesoría jurídica; así como a entidades de carácter público, como son autoridades judiciales y/o administrativas, en cuyo caso,
          no se requerirá de su autorización para transferir sus datos; esto es, CLICK SEGUROS INC, S.A. DE C.V., podrá comunicar y compartir sus datos personales, en caso de que así lo soliciten
          dichas autoridades, sin perjuicio de comunicarles el presente aviso de privacidad a los destinatarios de sus datos personales, para los fines que resulten pertinentes.
        </p>
        <p>
          En adición a lo anterior, se hace de su conocimiento que, para fines estadísticos, medición de productividad, producción y promoción de productos financieros, los datos que Usted nos
          proporcione también podrán ser transferidos a las Instituciones Financieras con las que CLICK SEGUROS INC, S.A. DE C.V., tenga relación jurídica en virtud de la celebración de acuerdos o
          contratos de carácter legal, en función de su propia naturaleza.
        </p>
        <p>Si usted hace uso de la página de internet sin que manifieste su oposición para que sus datos personales sean transferidos, se entenderá que ha otorgado su consentimiento para ello.</p>
        <p>
          Al ingresar y utilizar la página web está aceptando los términos y condiciones de este aviso de privacidad y autoriza de manera expresa su consentimiento para que sean recabados los datos
          personales que ha ingresado a fin de hacer uso de nuestros servicios, utilizando para tal efecto medios electrónicos, en términos de lo dispuesto por el artículo 17 de la Ley Federal de
          Protección de Datos Personales en Posesión de Particulares y artículo 19 del Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de Particulares. En caso de no aceptar
          en forma absoluta y completa los términos y condiciones de este convenio, deberá abstenerse de acceder y utilizar el sitio web.
        </p>
        <p>
          Asimismo, la sola utilización de la página de internet, otorga al que ingrese y utilice nuestros servicios, la condición de usuario e implica la aceptación, plena e incondicional, de todas y
          cada una de las condiciones generales y particulares incluidas en este aviso de privacidad.{" "}
        </p>
        <h3>OBTENCIÓN DE LOS DATOS PERSONALES</h3>
        <p>
          Se obtienen a partir de la información que su titular o representante legal nos proporcione de manera personal, o bien, directamente del titular por cualquier medio electrónico, óptico,
          sonoro, visual, o a través de cualquier otra tecnología
        </p>
        <h3>¿CON QUÉ OPCIONES CUENTO PARA LIMITAR EL USO O DIVULGACIÓN DE MIS DATOS?</h3>
        <p>
          En caso que no desee que sus datos personales sean tratados para los fines secundarios descritos con anterioridad, Usted podrá revocar el consentimiento que nos ha otorgado para el
          tratamiento de sus datos personales, a fin de que dejemos de hacer uso de los mismos.
        </p>
        <p>Para ello, es necesario que presente su petición por escrito a través del Servicio de Atención a Clientes, el cual se encuentra ubicado en nuestras oficinas. </p>
        <p>Su petición deberá ir acompañada de la siguiente información: (i) nombre completo, (ii) usuario, (iii) correo electrónico, y (iv) teléfono de contacto.</p>
        <p>
          En un plazo máximo de 10 (diez) días atenderemos su petición y le informaremos sobre la procedencia de la misma a través del correo electrónico o teléfono de contacto que nos haya
          notificado.
        </p>
        <p>
          Ello, desde luego, no constituirá una negativa en la contratación de los servicios y productos que ofrecemos. Sin embargo, cabe mencionar que existen datos personales que resultan necesarios
          para que Usted pueda solicitar o contratar nuestros servicios, en este caso, su negativa para hacer uso de sus datos personales, podrá constituir un impedimento para que podamos seguir
          prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
        </p>
        <h3>MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN (DERECHOS “ARCO”)</h3>
        <p>
          Los “Derechos ARCO” se refieren a que Usted de conformidad con la legislación aplicable, tendrá derecho de acceder a sus datos personales que poseemos y al detalle del tratamiento de los
          mismos; así como a rectificarlos cuando sean inexactos o se encuentren incompletos, debiendo solicitar su corrección, señalando con toda precisión de qué datos se trata y debiendo adjuntar
          la documentación que acredite o ampare la modificación requerida; cancelarlos cuando considere que no se requieren para alguna de las finalidades señaladas en el presente aviso de
          privacidad, estén siendo utilizados para finalidades no consentidas o haya finalizado la relación contractual o de servicios, siempre y cuando esté permitido por la ley y exista una causa
          que justifique dicha acción, por lo que de ser procedente su solicitud de cancelación, se bloquearán y suprimirán sus datos personales y finalmente Usted tiene derecho en todo momento y por
          causa legítima a oponerse al tratamiento de sus datos personales.
        </p>
        <p>
          En términos de lo dispuesto en los artículos 22 y 28 de la Ley de la materia, Usted podrá ejercer los derechos de acceso, rectificación, cancelación u oposición al tratamiento de sus datos
          (“Derechos ARCO”), debiendo presentar solicitud por escrito en términos de lo que señala el artículo 29 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares,
          en nuestras oficinas ubicadas en Avenida La Herradura No. 11, Col. Lomas del Huizachal, C.P. 53840, en el Estado de México,{" "}
        </p>
        <p>
          Asimismo, se deberá de acreditar la identidad del titular y, en su caso, la de su representante legal, así como la personalidad de este último, por lo que la solicitud se deberá acompañar de
          copia simple de una identificación oficial de usted como titular de los datos personales, así como de su representante legal, en caso de que éste sea quien presente la solicitud.
        </p>
        <p>Entre las identificaciones oficiales válidas se encuentran: credencial para votar, pasaporte, cartilla militar, cédula profesional, licencia para conducir y/o documento migratorio.</p>
        <p>La personalidad de su representante legal, en su caso, se podrá acreditar, cuando se trate de persona física, se podrá elegir cualquiera de las siguientes tres opciones: </p>
        <ul>
          <li>1. La presentación de una carta poder simple suscrita ante dos testigos, anexando copia simple de sus identificaciones oficiales;</li>
          <li>2. Mediante instrumento público (documento suscrito por un Notario Público);</li>
          <li>
            3. Acudiendo usted y su representante a declarar en comparecencia ante el responsable. Es importante tener en cuenta que la identidad del titular y su representante legal, así como la
            personalidad de este último, deberán quedar debidamente acreditadas previo al ejercicio del derecho de que se trate, en caso de que resulte procedente, mediante la presentación de los
            documentos originales antes señalados o copia certificada de los mismos, para su cotejo.
          </li>
        </ul>
        <p>
          También se deberán de tomar en cuenta las siguientes reglas de representación en caso de solicitudes relacionadas con datos personales de menores de edad, personas en estado de interdicción
          o incapacidad declarada por ley. Para el ejercicio de derechos ARCO de este grupo de titulares, además de la presentación de la solicitud con la información descrita en los párrafos que
          anteceden, se deberán aportar los siguientes documentos, según sea el caso:
        </p>
        <p>Para solicitudes de ejercicio de derechos ARCO de datos personales de menores de edad: </p>
        <p>Si los padres ejercen la patria potestad y son los que presenten la solicitud:</p>
        <ul>
          <li>• Documento que acredite la identidad de menor.</li>
          <li>• Acta de nacimiento del menor.</li>
          <li>• Identificación oficial del padre o de la madre, que pretenda ejercer el derecho.</li>
          <li>
            • Carta en la que se manifieste, bajo protesta de decir verdad, que el padre o la madre es quien ejerce la patria potestad del menor, y que no se encuentra dentro de alguno de los
            supuestos legales de suspensión o limitación de la patria potestad.
          </li>
        </ul>
        <p>Si una persona distinta a los padres es quien ejerce la patria potestad, y es quien presenta la solicitud: </p>
        <ul>
          <li>• Documento que acredite la identidad de menor.</li>
          <li>• Acta de nacimiento del menor.</li>
          <li>• Documento legal que acredite la posesión de la patria potestad.</li>
          <li>• Identificación oficial de quien presenta la solicitud y posee la patria potestad.</li>
          <li>
            • Carta en la que se manifieste, bajo protesta de decir verdad, que ejerce la patria potestad del menor, y que no se encuentra dentro de alguno de los supuestos legales de suspensión o
            limitación de la patria potestad.
          </li>
        </ul>
        <p>Cuando un tutor es quien ejerce la patria potestad: </p>
        <ul>
          <li>• Documento que acredite la identidad de menor.</li>
          <li>• Acta de nacimiento del menor.</li>
          <li>• Documento legal que acredite la tutela.</li>
          <li>• Identificación oficial del tutor.</li>
          <li>
            • Carta en la que se manifieste, bajo protesta de decir verdad, que ejerce la tutela del menor, y que no se encuentra dentro de alguno de los supuestos legales de suspensión o limitación
            de la tutela.
          </li>
        </ul>
        <p>Para solicitudes de derechos ARCO de datos personales de personas en estado de interdicción o incapacidad legal: </p>
        <ul>
          <li>• Documento que acredite la identidad del titular de los datos personales.</li>
          <li>• Instrumento legal de designación del tutor.</li>
          <li>• Identificación oficial del tutor.</li>
          <li>
            • Carta en la que se manifieste, bajo protesta de decir verdad, que ejerce la tutela, y que no se encuentra dentro de alguno de los supuestos legales de suspensión o limitación de la
            misma.
          </li>
        </ul>
        <p>
          Asimismo, de conformidad con el artículo 32 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, los plazos y procedimiento para la atención de las
          solicitudes de ejercicio de derechos ARCO, una vez que se presentó la solicitud y que ésta cumplió con los requisitos antes descritos, el responsable ante el cual se presentó deberá realizar
          lo siguiente:
        </p>
        <ul>
          <li>• En un plazo de 20 (veinte) días hábiles, contados a partir del día siguiente a la recepción de la solicitud, deberá informarle si procede o no el ejercicio del derecho solicitado.</li>
          <li>
            • En caso de que haya procedido el ejercicio del derecho, deberá llevar a cabo las acciones necesarias para hacerlo efectivo, en un plazo de 15 (quince) días hábiles, contados a partir del
            día siguiente en el que le haya notificado la respuesta anterior.
          </li>
        </ul>
        <p>Los plazos antes señalados se pueden ampliar por un periodo igual, cuando esté justificado.</p>
        <p>
          Atento a lo anterior, Usted debe tener la plena seguridad que los datos personales proporcionados a CLICK SEGUROS INC, S.A. DE C.V., serán tratados de conformidad con lo que señala la Ley
          Federal de Protección de Datos Personales en Posesión de los Particulares y su respectivo Reglamento, siendo debidamente protegidos a través de las medidas de seguridad administrativas,
          físicas y técnicas a fin de garantizar su confidencialidad y evitar su pérdida daño, alteración, destrucción, uso, acceso o divulgación indebida de sus datos personales.
        </p>
        <p>
          Si Usted considera que su derecho de protección de datos personales ha sido vulnerado por alguna conducta de nuestros empleados, de nuestras actuaciones o respuestas o presume que en el
          tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley, puede contactarnos en el área de Servicio de Atención al Cliente, ello, sin perjuicio de
          su derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI). Para más información visite www.inai.org.mx
        </p>
        <p>
          CLICK SEGUROS INC, S.A. DE C.V., se reserva el derecho de cambiar, modificar, complementar y//o alterar en cualquier momento el presente AVISO DE PRIVACIDAD, mismo que será notificado a
          través de nuestra página web https://www.clicksegurosvip.com/ o bien vía correo electrónico que para el efecto nos haya proporcionado, esta última opción no será obligatoria para CLICK
          SEGUROS INC, S.A. DE C.V., por lo cual, el o los usuarios están obligados a consultar periódicamente los términos y condiciones actuales de este aviso de privacidad.
        </p>
        <h3>Propiedad intelectual</h3>
        <p>
          Los derechos de propiedad intelectual respecto de los signos distintivos y dominios del Portal, son propiedad exclusiva de CLICK SEGUROS INC, S.A. DE C.V. y los materiales contenidos en el
          mismo se encuentran protegidos por las leyes mexicanas.
        </p>
        <p>
          El usuario no adquiere ningún derecho de propiedad intelectual por el simple uso de los Servicios y Contenidos del Portal y en ningún momento dicho uso será considerado como una autorización
          ni licencia para utilizar los Servicios y Contenidos con fines distintos a los que se contemplan en los presentes Términos y Condiciones de Uso y Privacidad.
        </p>
        <p>
          Asimismo, queda estrictamente prohibido el uso y explotación de la información contenida en el portal, con fines comerciales, incluyendo su descarga, impresión, divulgación, publicación,
          reproducción, distribución y transformación, salvo que cuente con la autorización previa y por escrito de CLICK SEGUROS INC, S.A. DE C.V.
        </p>
        <p>Cualquier reproducción, uso indebido o sin autorización de su titular constituye una infracción o delito en términos de la Ley Federal de Protección a la Propiedad Industrial.</p>
        <h3>TERMINOS Y CONDICIONES</h3>
        <h3>Uso y restricciones</h3>
        <p>
          La utilización del portal de CLICK SEGUROS INC, S.A. DE C.V. en el sitio web https://www.clicksegurosvip.com/(“Portal”) expresa la adhesión plena y sin reservas del usuario a los presentes
          Términos y Condiciones de Uso y Privacidad. A través del Portal, el usuario accesará y/o utilizará diversos servicios y contenidos (en lo sucesivo, los “Servicios y Contenidos”), puestos a
          disposición de los usuarios por CLICK SEGUROS INC, S.A. DE C.V. y/o sus subsidiarias y afiliadas. CLICK SEGUROS INC, S.A. DE C.V. tendrá el derecho a negar, restringir o condicionar al
          usuario el acceso al Portal, total o parcialmente, a su entera discreción, así como a modificar los Servicios y Contenidos del Portal, en cualquier momento y sin necesidad de previo aviso.
          El contenido de este sitio, tiene como finalidad primordial, brindar información general a nuestros clientes, así como al público interesado, y de ninguna manera constituye una relación que
          obligue al usuario y a CLICK SEGUROS INC, S.A. DE C.V. a mantener una relación contractual, si así no lo desea.{" "}
        </p>
        <p>
          Asimismo, hacemos de su conocimiento, que en nuestro sitio web podrá encontrar vínculos a sitios de terceros que no están bajo el control de CLICK SEGUROS INC, S.A. DE C.V., por lo que no
          nos hacemos responsables del contenido e información de estos.
        </p>
        <p>
          El usuario reconoce que CLICK SEGUROS INC, S.A. DE C.V., no controla ni censura previamente el contenido disponible en el sitio web, por tal motivo CLICK SEGUROS INC, S.A. DE C.V., no asume
          ningún tipo de responsabilidad por el contenido provisto en dicho sitio web que pudiere haber sido fijado por proveedores independientes o ajenos a CLICK SEGUROS INC, S.A. DE C.V., y no
          tiene el control editorial sobre el contenido, información y/o material generado y/o provisto por dichos terceros. Todas las opiniones, consejos, declaraciones, servicios, ofertas u otras
          informaciones o contenidos expresados o puestos a disposición del público por terceros, pertenecen a su respectivo autor y CLICK SEGUROS INC, S.A. DE C.V., no asume responsabilidad alguna
          frente a ello. De la misma forma, CLICK SEGUROS INC, S.A. DE C.V., no garantiza la exactitud, veracidad, amplitud y/o utilidad de cualquier contenido provisto por los terceros de que se
          trate.{" "}
        </p>
        <h3>USO DE “COOKIES” </h3>
        <p>
          Los “cookies” son archivos de texto que son descargados automáticamente y almacenados en el disco duro del equipo de cómputo del usuario al navegar en una página de internet específica, que
          permiten que el sitio web pueda consultar la actividad previa del usuario, entre ellos, sus preferencias de visualización de las páginas en ese servidor, nombre y contraseña.
        </p>
        <p>
          Mientras que, las “web beacons” también llamado píxel único, gif claro (clear GIF), web bug, o pixel tag, es una imagen gráfica transparente, que es colocada en una página web o en un email
          y que es usado para monitorear el comportamiento de un visitante. Cuando un navegador carga la imagen, se almacena información del usuario, como el tiempo que permanece en la página, el tipo
          de navegador, la dirección IP, etc.
        </p>
        <p>
          Por lo que, hacemos de su conocimiento que en CLICK SEGUROS INC, S.A. DE C.V. utilizamos “cookies” y “web beacons” para obtener información personal de Usted, sin embargo, éstas pueden ser
          deshabilitadas.
        </p>
        <h3>Exclusión de responsabilidad</h3>
        <p>
          Ni CLICK SEGUROS INC, S.A. DE C.V. ni sus proveedores o socios comerciales serán responsables de cualquier daño o perjuicio que sufra el usuario por consecuencia de inexactitudes, errores
          tipográficos y cambios o mejoras que se realicen periódicamente a los Servicios y Contenidos.{" "}
        </p>
        <p>
          Las recomendaciones y consejos obtenidos a través del Portal son de naturaleza general, por lo que no deben tomarse en cuenta en la adopción de decisiones personales y empresariales,
          liberando a CLICK SEGUROS INC, S.A. DE C.V. de cualquier responsabilidad al respecto. Para ello se debe consultar a un profesional apropiado que pueda asesorar al usuario de acuerdo con sus
          necesidades específicas.
        </p>
        <p>
          Asimismo, se deslinda a CLICK SEGUROS INC, S.A. DE C.V. de la información y de las fotografías que los agentes de seguros con acceso a al portal añadan, así como del manejo que hagan del
          mismo, toda vez que ellos aceptan la total y completa responsabilidad del uso que le den.
        </p>
        <h3>Enlaces a terceros </h3>
        <p>
          El sitio web pudiera contener enlaces a otros sitios que pudieran ser de su interés o beneficio. Una vez que usted dé clic en dichos enlaces y abandone nuestra página, ya no tenemos control
          sobre al sitio al que es redirigido y por lo tanto, no somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están
          sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con los mismos.{" "}
        </p>
        <p>
          Asimismo, CLICK SEGUROS INC, S.A. DE C.V. no es ni podrá ser considerado como proveedor de los bienes y/o servicios que se llegaren a ofrecer en este sitio. En caso de que en este sitio
          hubiere incluidos enlaces a otros sitios diversos no implica la aprobación, respaldo, patrocinio, recomendación o garantía, por parte de CLICK SEGUROS INC, S.A. DE C.V. de los servicios y
          bienes que se comercializan en los mismos ni del contenido de dichas páginas. No existe ningún tipo de relación laboral, asociación o sociedad, entre CLICK SEGUROS INC, S.A. DE C.V. y dichos
          terceros. Toda asesoría, consejo, declaración, información y contenido de las páginas de terceros enlazadas a este portal representan las opiniones y juicios de dicho tercero,
          consecuentemente, CLICK SEGUROS INC, S.A. DE C.V. no será responsable de ningún daño o perjuicio que sufra el usuario a consecuencia de los mismos
        </p>
        <h3>Responsabilidad del agente de seguros que utilice el portal</h3>
        <p>Los agentes de seguros que utlicien y tengan acceso a la plataforma de Click Plus aceptan que son los únicos responsable por:</p>
        <ul>
          <li>• El uso que le de al correo electrónico que se me proporcione.</li>
          <li>• La información que suba al portal, incluyendo la información que sube en su tarjeta virtual, por ejemplo: nombre, teléfono, mail de contacto, redes sociales, etcétera.</li>
          <li>• El manejo y trato que se le de a los clientes.</li>
          <li>• El uso en general que se haga de la plataforma digital.</li>
        </ul>
        <p>
          Por lo que se deslinda a CLICK SEGUROS INC, S.A. DE C.V. de cualquier responsabiliadad o conflicto que pudiera llegar a sucitarse derivado del uso que el agente haga de la plataforma de
          Click Plus.
        </p>
        <p>
          En el supuesto de que los agentes de seguros llegasen a utilizar un correo electrónico o material que incluya la imagen y/o denominaciones de “CLICK SEGUROS”, se hacen responsables del buen
          uso y de sacar en paz y a salvo a las empresas de cualquier eventualidad que surja derivado del uso que le den al mismo.
        </p>
        <p>
          Asimismo, CLICK SEGUROS INC, S.A. DE C.V. puede limitar, modificar, eliminar o quitar libremente y sin necesidad de autorización del agente de seguros, cualquier información que suban al
          portal. Además de aclarar que queda estrictamente prohibido poner URLs en la plataforma de Click Plus.
        </p>
        <p>
          También el agente de seguros que utilice la plataforma será responsable del trato que se le de al usuario y de la información que sube en su tarjeta virtual, por ejemplo: nombre, teléfono,
          mail de contacto, redes sociales, asi como de lainformación que edita en su portal publico.
        </p>
        <h3>Propiedad intelectual</h3>
        <p>
          Los derechos de propiedad intelectual respecto de los signos distintivos y dominios del Portal, son propiedad exclusiva de CLICK SEGUROS INC, S.A. DE C.V. y los materiales contenidos en el
          mismo se encuentran protegidos por las leyes mexicanas.
        </p>
        <p>
          El usuario no adquiere ningún derecho de propiedad intelectual por el simple uso de los Servicios y Contenidos del Portal y en ningún momento dicho uso será considerado como una autorización
          ni licencia para utilizar los Servicios y Contenidos con fines distintos a los que se contemplan en los presentes Términos y Condiciones de Uso y Privacidad.
        </p>
        <p>
          Asimismo, queda estrictamente prohibido el uso y explotación de la información contenida en el portal, con fines comerciales, incluyendo su descarga, impresión, divulgación, publicación,
          reproducción, distribución y transformación, salvo que cuente con la autorización previa y por escrito de CLICK SEGUROS INC, S.A. DE C.V.
        </p>
        <p>Cualquier reproducción, uso indebido o sin autorización de su titular constituye una infracción o delito en términos de la Ley Federal de Protección a la Propiedad Industrial.</p>
        <h3>Política de devoluciones</h3>
        <p>
          Los reembolsos a clientes generan una exposición que puede involucrar a CLICK SEGUROS INC, S.A. DE C.V. en el lavado de dinero o evasión fiscal. Debido a esto, es necesario establecer
          tiempos y controles adicionales al proceso de reembolso de saldos a favor. A continuación los requisitos y tiempos que se han definido para dar trámite a un reembolso.
        </p>
        <p>Requisitos indispensables para iniciar el trámite:</p>
        <p>1. Cuando se presente un saldo a favor y el cliente lo reclame, debemos asegurar:</p>
        <ul>
          <li>• El cliente no debe presentar en su estado de cuenta ninguna factura vencida, la cuenta debe estar 100% al corriente.</li>
          <li>• Si hubiera cartera vencida, debe confirmarse que el saldo a favor se aplicará a las facturas abiertas, vencidas y no vencidas.</li>
          <li>• Si hubiera algún remanente o no hay facturas para aplicar, daremos trámite a la solicitud de reembolso de acuerdo con lo siguiente:</li>
        </ul>

        <p>2. Solicitar al cliente carta membretada de la empresa firmada por su representante legal en el que nos detalle lo siguiente:</p>
        <ul>
          <li>• Motivo por el que solicita el reembolso.</li>
          <li>• Importe de este.</li>
          <li>• Nombre del banco.</li>
          <li>• Número de cuenta especificando el nombre del titular de la misma.</li>
          <li>• Número de Clabe interbancaria la cual consiste en 18 dígitos.</li>
          <li>• Tratándose de depósitos electrónicos, nos deben anexar el comprobante electrónico de la cuenta de origen de los fondos, y solamente a esa cuenta se le deberá devolver el dinero.</li>
          <li>• Dicha cuenta deberá estar a nombre del representante legal y/o Persona moral que firma la cuenta y emite la carta de solicitud de fondos.</li>
          <li>• En caso de no coincidir esta información, no se podrá hacer el reembolso correspondiente.</li>
          <li>• En caso en que un cliente se niegue a dar esta información, se tendrá que contactar al Banco que maneja la cuenta de Ingram Micro para investigar la procedencia de los fondos.</li>
          <li>
            • Insertar la siguiente leyenda en la carta: DECLARO BAJO PROTESTA DE DECIR VERDAD QUE ESTA SOLICITUD DE REEMBOLSO NO SE TRATA DE UNA PRACTICA DE LAVADO DE DINERO Y/O EVASION DE IMPUESTOS.
          </li>
        </ul>

        <ul>
          <li>3. Comprobante de pago o de la transferencia legible en donde se demuestre el monto a reembolsar.</li>
          <li>4. Acta Constitutiva o poder del representante legal en donde se acredite que es el representante legal con facultades de administración de quien firma la carta.</li>
          <li>
            5. Carátula del estado de cuenta bancario en donde se observe la misma información bancaria del depósito realizado (Banco, número de cuenta, clabe interbancaria, razón social de persona
            moral o física).
          </li>
          <li>6. RFC ya sea persona física o moral.</li>
          <li>7. Identificación oficial vigente con foto y firma (credencial de elector o pasaporte).</li>
          <li>8. No se aceptarán correos del cliente solicitando reembolso. Es necesaria la carta anteriormente descrita y firmada por el representante legal.</li>
        </ul>
        <h3>¿Cuánto dura el trámite del reembolso?</h3>

        <ul>
          <li>
            - El tiempo para realizar el reembolso del saldo a favor, siempre y cuando se cumplan los requisitos anteriormente descritos, es de un lapso de un mes una vez que se reciba la información
            completa.
          </li>
          <li>- Cualquier excepción a este procedimiento deberá ser autorizada por el Gerente. de Crédito y Cobranza y por el Director de Finanzas.</li>
        </ul>
        <h3>Modificaciones</h3>
        <p>
          CLICK SEGUROS INC, S.A. DE C.V. tiene el derecho de modificar en cualquier momento los Términos y Condiciones de Uso y Privacidad mediante simple publicación de los nuevos Términos y
          Condiciones de Uso y Privacidad, en el entendido de que en el caso de que el usuario no esté de acuerdo con las modificaciones, deberá abstenerse de ingresar y usar el Portal.
        </p>
        <h3>Click Plus Premium</h3>
        <p>Se hace del conocimiento de los usuarios que al utilizar Click Plus Premium se van a tener los siguientes beneficios en comparación a Click Plus Free:</p>
        <ul>
          <li>
            Cotizador.
            <ul>
              <li>Cotizador automóviles con respuesta de costo.</li>
            </ul>
          </li>
          <li>• Formulario para cotizar.</li>
          <li>• Posibilidad de venta de Seguros de Vida.</li>
          <li>
            Un sitio público adicional, por ejemplo: para persona moral.
            <ul>
              <li>Personalizables.</li>
              <li>Imágenes a elegir por sub-ramo adicionales.</li>
              <li>Plantillas a elegir adicionales.</li>
              <li>Tipos de letra adicionales.</li>
              <li>Agrega contacto por WhatsApp.</li>
            </ul>
          </li>
          <li>• Espacio en correo electrónico adicional (25GB total).</li>
        </ul>

        <p>Al momento de suscribirte a la versión premium de Click Plus, estas aceptando que se haga el cobro del precio para su uso.</p>

        <p>
          El costo para poder utilizar la versión Premium de Click Plus será de $100.00 (cien pesos 00/100 M.N.) el cual se pagará al hacer CLICK SEGUROS INC, S.A. DE C.V. un descuento al agente de
          seguros de las comisiones que generé.
        </p>
        <p>
          En caso de el agente no llegase a tener comisiones por 2 meses (8 semanas) consecutivos, con los cuales se pueda realizar el pago de la versión Premium, se le suspenderá automáticamente la
          versión premium del portal y se pasará inmediatamente a la versión Click Plus Free.
        </p>
        <h3>Cambios de precios e impuestos de Click Plus Premium</h3>
        <p>“CLICK SEGUROS” podrá modificar ocasionalmente el precio de las suscripciones de pago, y comunicará cualquier cambio de precio con la debida antelación. </p>
        <p>Los cambios de precio entrarán en vigor al inicio del siguiente periodo de suscripción que siga a la fecha del cambio de precio.</p>
        <p>
          Si sigue utilizando el Servicio de Click Plus Premium una vez que el cambio de precio entre en vigor, se entenderá que ha aceptado el nuevo precio. Si no está de acuerdo con un cambio de
          precio, podrá rechazar dicho cambio dándose de baja de la Suscripción de pago correspondiente antes de que el cambio de precio entre en vigor.
        </p>
        <h3>Renovación y cancelación de Click Plus Premium</h3>
        <p>La suscripción de pago se renovará automáticamente, a menos que cancele su Suscripción de pago antes de que finalice el periodo de suscripción en curso. </p>
        <p>
          Para la cancelación de Click Plus, el usuario se deberá de cancelar su suscripción de manera directa en el Portal de Click Plus. La cancelación tarda una semana para que se haga efectiva,
          por lo que, se hará efectiva a los siete días de la fecha de cancelación.
        </p>
        <p>La cancelación entrará en vigor el día siguiente al último día del periodo de suscripción actual, y se le cambiará a la versión Click Plus free. </p>
        <p>No proporcionamos reembolsos ni créditos por ningún periodo de suscripción parcial.</p>
        <p>
          Al ingresar a esta página web y/o al proporcionar sus datos, se entiende, para todos los efectos legales a que haya lugar, que manifiesta expresamente lo siguiente: “Doy mi consentimiento
          para el tratamiento de mis datos personales sensibles y personales financieros o patrimoniales para las finalidades necesarias y correspondientes a la relación jurídica con CLICK SEGUROS
          INC, S.A. DE C.V. (Negarse al tratamiento de sus datos personales tendrá́ como consecuencia la imposibilidad de establecer una relación jurídica con CLICK SEGUROS INC, S.A. DE C.V.)”.{" "}
        </p>
      </div>
      <div className="modal-footer">
        <a href="#!" className="modal-close waves-effect  btn-flat">
          Cerrar
        </a>
      </div>
    </div>
  );
}
