import React, { useEffect, useState } from "react";
import { getAllLeadsApi } from "../../api/Admin/leads";
import { Preloader } from "react-materialize";
import useAuth from "../../hooks/useAuth";

export default function GetAllLeads() {
	const { logout } = useAuth();
	const [allLeads, setAllLeads] = useState(null);
	const [reloadLeads, setReloadLeads] = useState(false); /* the status never change */
	const [pageSelected, changePage] = useState(1);
	const [nameinput, changeName] = useState("");
	const [gerencia, changeGerencia] = useState("");
	const [tipovend, changeTipovend] = useState("");

	const [optgerencia, changeOptGerencia] = useState([{ valor: "", nombre: "Todos" }]);
	const [opttipovend, changeOptTipovend] = useState([{ valor: "", nombre: "Todos" }]);


	const deleteDuplicate = (objeto) => {
		let valoresUnicos = {}; // objeto auxiliar para mantener un seguimiento de los valores únicos
		let objetoSinDuplicados = objeto.filter(function(item) {
			return valoresUnicos.hasOwnProperty(item.valor) ? false : (valoresUnicos[item.valor] = true);
		});

		return objetoSinDuplicados;
	}

	const getOptionsByType = (typestr, arryobj = []) => {
		let data = [{ valor: "", nombre: "Todos" }];
		if (typestr === "gerencia") {
			//data = arryobj.map((item)=>{ return {valor:item.Gerencia, nombre: item.Gerencia} });
			for (let index = 0; index < arryobj.length; index++) {
				data[index + 1] = { valor: arryobj[index].Gerencia, nombre: arryobj[index].Gerencia };
				//arryobj[index].Gerencia;
			}
		} else if (typestr === "tipovend") {
			for (let index = 0; index < arryobj.length; index++) {
				data[index + 1] = { valor: arryobj[index].TipoVend, nombre: arryobj[index].TipoVend };
			}
			
		} else {

		}

		//let uniqueData = deleteDuplicate(data);
		let uniqueData = deleteDuplicate(data)
		console.log("Opcion seleccionada " + typestr + " :", data);
		return uniqueData;
	}

	useEffect(() => {
		(async () => {
			const response = await getAllLeadsApi(logout);
			setAllLeads(response.DataResponse || []);
			//console.log(response);
			changeOptGerencia(getOptionsByType("gerencia", response.DataResponse));
			changeOptTipovend(getOptionsByType("tipovend", response.DataResponse));
		})();
		console.log("Loading leads by users...");
	}, []);


	useEffect(() => {
		let pageByName = (nameinput !== "") ? changePage(1) : pageSelected;
		
		let options = { name: nameinput, gerencia: gerencia, tipovend: tipovend, page: pageByName };
		(async () => {
			const response = await getAllLeadsApi(logout, options);
			setAllLeads(response.DataResponse || []);
			console.log(response);
		})();
		console.log("Update the params ...", options);
	}, [nameinput, gerencia, tipovend, pageSelected]);


	const showLeadsPaginator = (page) => {
		let result = pageSelected + page;
		let newPage = result == 0 ? 1 : result;
		changePage(pageSelected + page);
	};

	if (!allLeads) return (<div className="c1 flex-c">
		<Preloader active color="blue" flashing={true} size="big" />
	</div>);

	return (
		<>
			<div className="c1">
				<div className="card">
					<div className="card-content">
						<div className="box-pad pb-1">
							<div className="search-inpt flex-start-center">
								<span className="material-icons i-search">search</span>
								<input
									placeholder="Busca un nombre"
									id=""
									type="text"
									value={nameinput}
									className="browser-default"
									onChange={(e) => changeName(e.target.value)}
								/>
							</div>
						</div>
						<div className="top-border mb-4">
							<ul className="rqst">
								<li></li>
								<li>
									<div className="">
										<label>Tipo de vendedor</label>
										<select
											className="browser-default"
											onChange={(e) => { changeTipovend(e.target.value) }}
										>
											{opttipovend.map((item, index) => {
												return (
													<option key={index} value={item.valor}>{item.nombre}</option>
												)
											})}
										</select>
									</div>
								</li>
								<li>
									<div className="">
										<label>Gerencia</label>
										<select
											className="browser-default"
											onChange={(e) => { changeGerencia(e.target.value) }}
										>
											{optgerencia.map((item, index) => {
												return (
													<option key={index} value={item.valor}>{item.nombre}</option>
												)
											})}
										</select>
									</div>
								</li>
							</ul>
						</div>
						<table className="striped centered">
							<thead>
								<tr>
									<th>Clave</th>
									<th>Nombre</th>
									<th>Portal</th>
									<th>Gerencia</th>
									<th>Tasa de conversión</th>
									<th>Tipo</th>
									<th>Total leads</th>
									<th>Total Abiertas</th>
									<th>Total Cerradas</th>
									<th>Total Contactos</th>
								</tr>
							</thead>
							<tbody>
								{allLeads.map((lead, index) => {
									return (
										<tr key={index}>
											<td>{lead.Clave}</td>
											<td>{lead.NombreCompleto}</td>
											<td>{lead.IDPortal}</td>
											<td>{lead.Gerencia}</td>
											<td>{lead.Tasa_Conver}%</td>
											<td>{lead.TipoVend}</td>
											<td>{lead.Total}</td>
											<td>{lead.Total_Abiertas}</td>
											<td>{lead.Total_CerradaG}</td>
											<td>{lead.Total_Contacts}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
						<div className="row">
							<div className="col m12 s12 text-center mt-3">
								<button
									className="btn orange btn-sm ml-2 mr-2"
									disabled={pageSelected === 1 ? true : false}
									onClick={() => {
										showLeadsPaginator(-1);
									}}
								>
									Anterior
								</button>
								<span
									style={{
										fontSize: "16px",
										fontWeight: "700",
									}}
								>
									{" "}
									Página {pageSelected}{" "}
								</span>
								<button
									className="btn orange btn-sm ml-2 mr-2"
									onClick={() => {
										showLeadsPaginator(+1);
									}}
								>
									Siguiente
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
