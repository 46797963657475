import React from "react";

const Footer = ({ styleclass, dataAgnFb }) => {
  const IconView = ({ category, url }) => {
    if (category === "Facebook") {
      return (
        <div className="icored">
          <a href={url} target="_blank">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M14.64,11.14l.5-3.54H11.69V5.32a1.79,1.79,0,0,1,2-1.94h1.51v-3A18.7,18.7,0,0,0,12.44.1C9.66.1,7.81,1.78,7.81,4.9V7.6h-3v3.54H7.89v8.59a13.09,13.09,0,0,0,1.94.17,14,14,0,0,0,1.94-.17V11.14Z" />
            </svg>
          </a>
        </div>
      );
    }
    if (category === "Twitter") {
      return (
        <div className="icored">
          <a href={url} target="_blank">
            <svg height="20" width="20" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.23 15.62">
              <path
                d="M.35,16.81a11.11,11.11,0,0,0,6,1.77A11.15,11.15,0,0,0,17.6,6.85a8.13,8.13,0,0,0,2-2,8.23,8.23,0,0,1-2.26.62A4,4,0,0,0,19,3.25a7.8,7.8,0,0,1-2.5,1A4,4,0,0,0,9.81,7.8,11.19,11.19,0,0,1,1.68,3.68,4,4,0,0,0,2.9,9a3.86,3.86,0,0,1-1.78-.5,3.94,3.94,0,0,0,3.16,3.92,3.93,3.93,0,0,1-1.78.07,3.94,3.94,0,0,0,3.68,2.74A7.88,7.88,0,0,1,.35,16.81Z"
                transform="translate(-0.35 -2.96)"
              />
            </svg>
          </a>
        </div>
      );
    }
    if (category === "Linkedin") {
      return (
        <div className="icored">
          <a href={url} target="_blank">
            <svg width="20" height="20" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <rect x="1.08" y="6.9" width="3.84" height="12.39" />
              <path d="M3,5.28A2.29,2.29,0,1,0,.71,3,2.29,2.29,0,0,0,3,5.28Z" />
              <path d="M11.05,12.79c0-1.74.8-2.78,2.33-2.78s2.09,1,2.09,2.78V19.3h3.82V11.45c0-3.32-1.88-4.93-4.51-4.93a4.31,4.31,0,0,0-3.73,2.05V6.9H7.37V19.3h3.68Z" />
            </svg>
          </a>
        </div>
      );
    }
    if (category === "YouTube") {
      return (
        <div className="icored">
          <a href={url} target="_blank">
            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.84.489c1.728.003 2.1.017 2.987.058 1.039.047 1.748.212 2.368.453.642.25 1.186.583 1.729 1.125a4.783 4.783 0 0 1 1.125 1.728c.241.62.406 1.33.453 2.368.044.964.057 1.32.059 3.471v1.104c-.001 1.634-.01 2.232-.032 2.848l-.008.197-.019.425c-.047 1.039-.212 1.748-.453 2.369a4.783 4.783 0 0 1-1.125 1.728 4.783 4.783 0 0 1-1.729 1.125c-.62.241-1.33.406-2.368.453l-.425.02-.197.007c-.616.023-1.214.03-2.848.032H9.253c-2.15-.002-2.507-.015-3.47-.059-1.039-.047-1.748-.212-2.369-.453a4.783 4.783 0 0 1-1.728-1.125 4.782 4.782 0 0 1-1.125-1.728c-.241-.621-.406-1.33-.454-2.369-.04-.886-.054-1.259-.057-2.987v-2.07C.053 7.48.067 7.108.107 6.221.155 5.183.32 4.474.561 3.853a4.782 4.782 0 0 1 1.125-1.728A4.782 4.782 0 0 1 3.414 1c.62-.241 1.33-.406 2.368-.453C6.67.507 7.042.492 8.77.489zm-.755 1.757H8.789c-1.696.003-2.05.017-2.926.057-.952.043-1.468.202-1.812.335-.455.177-.78.389-1.122.73A3.022 3.022 0 0 0 2.2 4.49c-.133.344-.292.86-.335 1.812-.04.876-.054 1.23-.057 2.926v2.032c.003 1.696.017 2.05.057 2.926.043.951.202 1.468.335 1.812.177.455.389.78.73 1.122.342.34.667.552 1.122.73.344.133.86.292 1.812.335.838.039 1.198.052 2.712.056h.442l.246.001H10.592l.443-.001c1.514-.004 1.874-.017 2.712-.056.951-.043 1.468-.202 1.812-.336.455-.177.78-.388 1.122-.73.34-.34.552-.666.73-1.121.133-.344.292-.86.335-1.812.038-.838.052-1.198.056-2.712v-2.46c-.004-1.514-.018-1.874-.056-2.712-.043-.952-.202-1.468-.336-1.812a3.022 3.022 0 0 0-.73-1.122 3.022 3.022 0 0 0-1.121-.73c-.344-.133-.86-.292-1.812-.335-.876-.04-1.23-.054-2.926-.057H9.805zm-.28 2.988a5.01 5.01 0 1 1 0 10.02 5.01 5.01 0 0 1 0-10.02zm0 1.758a3.252 3.252 0 1 0 0 6.504 3.252 3.252 0 0 0 0-6.504zm5.208-3.127a1.17 1.17 0 1 1 0 2.342 1.17 1.17 0 0 1 0-2.342z"
                fillRule="evenodd"
              />
            </svg>
          </a>
        </div>
      );
    }
    if (category === "Instagram") {
      return (
        <div className="icored">
          <a href={url} target="_blank">
            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.84.489c1.728.003 2.1.017 2.987.058 1.039.047 1.748.212 2.368.453.642.25 1.186.583 1.729 1.125a4.783 4.783 0 0 1 1.125 1.728c.241.62.406 1.33.453 2.368.044.964.057 1.32.059 3.471v1.104c-.001 1.634-.01 2.232-.032 2.848l-.008.197-.019.425c-.047 1.039-.212 1.748-.453 2.369a4.783 4.783 0 0 1-1.125 1.728 4.783 4.783 0 0 1-1.729 1.125c-.62.241-1.33.406-2.368.453l-.425.02-.197.007c-.616.023-1.214.03-2.848.032H9.253c-2.15-.002-2.507-.015-3.47-.059-1.039-.047-1.748-.212-2.369-.453a4.783 4.783 0 0 1-1.728-1.125 4.782 4.782 0 0 1-1.125-1.728c-.241-.621-.406-1.33-.454-2.369-.04-.886-.054-1.259-.057-2.987v-2.07C.053 7.48.067 7.108.107 6.221.155 5.183.32 4.474.561 3.853a4.782 4.782 0 0 1 1.125-1.728A4.782 4.782 0 0 1 3.414 1c.62-.241 1.33-.406 2.368-.453C6.67.507 7.042.492 8.77.489zm-.755 1.757H8.789c-1.696.003-2.05.017-2.926.057-.952.043-1.468.202-1.812.335-.455.177-.78.389-1.122.73A3.022 3.022 0 0 0 2.2 4.49c-.133.344-.292.86-.335 1.812-.04.876-.054 1.23-.057 2.926v2.032c.003 1.696.017 2.05.057 2.926.043.951.202 1.468.335 1.812.177.455.389.78.73 1.122.342.34.667.552 1.122.73.344.133.86.292 1.812.335.838.039 1.198.052 2.712.056h.442l.246.001H10.592l.443-.001c1.514-.004 1.874-.017 2.712-.056.951-.043 1.468-.202 1.812-.336.455-.177.78-.388 1.122-.73.34-.34.552-.666.73-1.121.133-.344.292-.86.335-1.812.038-.838.052-1.198.056-2.712v-2.46c-.004-1.514-.018-1.874-.056-2.712-.043-.952-.202-1.468-.336-1.812a3.022 3.022 0 0 0-.73-1.122 3.022 3.022 0 0 0-1.121-.73c-.344-.133-.86-.292-1.812-.335-.876-.04-1.23-.054-2.926-.057H9.805zm-.28 2.988a5.01 5.01 0 1 1 0 10.02 5.01 5.01 0 0 1 0-10.02zm0 1.758a3.252 3.252 0 1 0 0 6.504 3.252 3.252 0 0 0 0-6.504zm5.208-3.127a1.17 1.17 0 1 1 0 2.342 1.17 1.17 0 0 1 0-2.342z"
                fillRule="evenodd"
              />
            </svg>
          </a>
        </div>
      );
    }
  };

  return (
    <>
      <div className={styleclass.bgPrimary + " container-fluid back-bl foot"}>
        <div className="head-txt">
          <strong>
            {dataAgnFb.agent_name !== "undefined" && dataAgnFb.agent_name !== null ? <span>{dataAgnFb.agent_name}</span> : <span style={{ color: "red" }}>"Debes llenar tu tarjeta virtual"</span>}
          </strong>{" "}
          | Agente de seguros
        </div>
        <div className="flex-alw mt-1">
          {dataAgnFb.redes_agente !== undefined && dataAgnFb.redes_agente !== null
            ? dataAgnFb.redes_agente.map((item, index) => {
                return <IconView key={index} category={item.category} url={item.agent_url_social}></IconView>;
              })
            : ""}
        </div>

        <div className="flex-alwb mt-1">
          <div className="listb">© 2022 · Derechos reservados</div>
          <div className="listb">
            <a className="modal-trigger" href="#modal-aviso">
              Aviso de privacidad
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
