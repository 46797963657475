import { collection, addDoc, onSnapshot, doc, updateDoc, where, query, getDocs } from "firebase/firestore";
import db from "../firebase/firebaseConfig";

export async function getUserDataFromFirebase(subdomain) {
  try {
    const q = query(collection(db, "cp_agents"), where("agent_url", "==", subdomain));
    const querySnapshot = await getDocs(q);
    const result = querySnapshot.docs[0].data();
    return result;
  } catch (error) {
    console.log(error);
  }
}
