import React, { useState } from "react";
import Header from "./Header";
import Services from "./Services";
import SliderArea from "./SliderArea";
import Contact from "./Contact";
import Footer from "./Footer";
import HeaderSide from "./HeaderSide";
import FormBudget from "../agentweb/FormBudget";
import ModalCotizar from "./ModalCotizar";

const MainPortal = ({ nameurl, styleclass, acceptConditions, dataAgnFb, changeFlag, isproduction, subdomain, firebaseUser, publicToken, products, isEdited, setIsEdited }) => {
  const [flagCotizar, changeCotizar] = useState("");

  const { menu_side } = styleclass;

  return (
    <>
      <div className="mor">
        <div className={isproduction ? "" : "portalmainpad"}>
          <div className={isproduction ? "" : "outline-gral"}>
            {menu_side === "horizontal" ? (
              <Header styleclass={styleclass} acceptConditions={acceptConditions} dataAgnFb={dataAgnFb} isproduction={isproduction} isEdited={isEdited} setIsEdited={setIsEdited} />
            ) : (
              <HeaderSide styleclass={styleclass} acceptConditions={acceptConditions} dataAgnFb={dataAgnFb} isproduction={isproduction}></HeaderSide>
            )}

            {/* Establecer la condición del formulario de la opción seleccionada */}
            <div className={flagCotizar !== "" ? "showForm" : "hideForm"} id="blockBudget">
              <FormBudget
                flagCotizar={flagCotizar}
                changeCotizar={changeCotizar}
                styleclass={styleclass}
                dataAgnFb={dataAgnFb}
                subdomain={subdomain}
                firebaseUser={firebaseUser}
                publicToken={publicToken}
              ></FormBudget>
            </div>
            <div className={flagCotizar === "" ? "showPortal" : "hidePortal"}>
              <div id="servicios"></div>
              <Services
                subdomain={subdomain}
                nameurl={nameurl}
                styleclass={styleclass}
                dataAgnFb={dataAgnFb}
                changeFlag={changeFlag}
                isproduction={isproduction}
                changeCotizar={changeCotizar}
                firebaseUser={firebaseUser}
              />
              <div id="blockNoFooter">
                <div id="aseguradoras"></div>
                <SliderArea styleclass={styleclass} dataAgnFb={dataAgnFb} changeFlag={changeFlag} isproduction={isproduction} />
                <div id="contacto"></div>
                <Contact styleclass={styleclass} dataAgnFb={dataAgnFb} changeFlag={changeFlag} isproduction={isproduction} firebaseUser={firebaseUser} publicToken={publicToken} />
              </div>
              <Footer styleclass={styleclass} dataAgnFb={dataAgnFb} isproduction={isproduction} />

              <ModalCotizar
                subdomain={subdomain}
                nameurl={nameurl}
                styleclass={styleclass}
                dataAgnFb={dataAgnFb}
                changeFlag={changeFlag}
                isproduction={isproduction}
                changeCotizar={changeCotizar}
                firebaseUser={firebaseUser}
              ></ModalCotizar>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainPortal;
