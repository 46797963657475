import { URL_CONSULTA_COMPANIAS, URL_SWITCH_COMPANIES } from "../../utils/constants";
import axios from "axios";
import { authFetch } from "../../utils/fetch";

export async function getCompaniesApi(logout) {
  try {
    const url = URL_CONSULTA_COMPANIAS;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: "k2q08aN+zAmcH5/dPMELrQ==",
      }),
    };
    const result = await authFetch(url, params, logout);
    return result ? result : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function switchCompaniesApi(companie, status, logout) {
  try {
    const url = URL_SWITCH_COMPANIES;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: "k2q08aN+zAmcH5/dPMELrQ==",
        TypeProcess: "2",
        Params: { IDCia: companie, Status: status },
      }),
    };
    const result = await authFetch(url, params, logout);
    console.log(result);
    return result ? result : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}
