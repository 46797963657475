import React, { useContext, useEffect, useState } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import { ContextGlobal } from "../../contexts/ContextGlobal";

const replaceContent = (servicio) => {
	if (servicio === "seguro_autos") {
		return {
			backImg: "autos.jpg",
			titulo: "Tranquilidad para ti y los tuyos.",
			subtitulo: "Nos preocupamos por ti y por lo más importante en tu vida",
			tituloServicio: "Encuentra el mejor seguro para tu automovil",
			textOneCol:
				"El Seguro de Automóviles es la perfecta solución para proteger a tu auto de los riesgos automovilísticos, como choches, robo, entre otros. También te permitirá solventar los daños que les puedas causar a terceros al conducir. Así mismo, ofrece alternativas para salvaguardar la integridad tanto del conductor y sus pasajeros.",
			textPone: "¡Existes varios niveles de protección! Según tus necesidades, elige entre:",
			textPtwo:
				"Cobertura Amplia: La protección más completa: te protege ante robo del vehículo, cubre los daños materiales que pueda sufrir tu auto y su reposición o reparación, además de brindarte asistencia legal y vial. Cubre los daños causados a terceros (bienes o personas) y los gastos médicos del conductor y los ocupantes.",
			textPthree:
				"Cobertura Limitada: Este solamente cubre los daños materiales causados a terceros (bienes o personas), los gastos médicos requeridos para el conductor y los ocupantes, te da respaldo ante robo del vehículo y te otorga asistencia legal y vial.",
			textPfour:
				"Responsabilidad Civil: Es la cobertura mínima y obligatoria requerida para poder circular, la cual te respalda para cubrir los daños causados por tu vehículo a terceros (bienes o personas)",
			textPfive: "¡Además, podrás complementar tu póliza con las coberturas adicionales que se adapten a tus necesidades y crear tu traje a la medida!",
			textPsix: "",
			textPseven: "",
			textPeight: "",
			textPnine: "",
			servicio: "autos",
			coberturaOne: "Cobertura amplia",
			coberturaTwo: "Cobertura limitada",
			coberturaThree: "Responsabilidad Civil",
			srvimg: "/images/image-product.png",
		};
	}
	if (servicio === "seguro_motos") {
		return {
			backImg: "motos.jpg",
			titulo: "Andar sobre dos ruedas nunca fue tan seguro",
			subtitulo: "Te brindamos las mejores opciones para proteger tu motocicleta",
			tituloServicio: "Encuentra el mejor seguro para tu moto",
			textOneCol:
				"Con un seguro de motocicletas, sea cual sea el tipo de moto que manejes y la utilidad que le des, estarás protegido y respaldado contra los riesgos vehiculares que puedan causarle daño a tu moto, a ti como conductor, a tus acompañantes y las afectaciones que puedas causarle a terceros.",
			textPone: "¡Existen varios niveles de protección! Según tus necesidades, elige entre:",
			textPtwo:
				"Cobertura Amplia: La protección más completa: te protege ante robo del vehículo, cubre los daños materiales que pueda sufrir tu auto y su reposición o reparación, además de brindarte asistencia legal y vial. Cubre los daños causados a terceros (bienes o personas) y los gastos médicos del conductor y los ocupantes.",
			textPthree:
				"Cobertura Limitada: Este solamente cubre los daños materiales causados a terceros (bienes o personas), los gastos médicos requeridos para el conductor y los ocupantes, te da respaldo ante robo del vehículo y te otorga asistencia legal y vial.",
			textPfour:
				"Responsabilidad Civil: Es la cobertura mínima y obligatoria requerida para poder circular, la cual te respalda para cubrir los daños causados por tu vehículo a terceros (bienes o personas)",
			textPfive: "¡Además, podrás complementar tu póliza con las coberturas adicionales que se adapten a tus necesidades y crear tu traje a la medida!",
			textPsix: "",
			textPseven: "",
			textPeight: "",
			textPnine: "",
			servicio: "motos",
			coberturaOne: "Cobertura amplia",
			coberturaTwo: "Cobertura limitada",
			coberturaThree: "Responsabilidad Civil",
			srvimg: "/images/moto.jpg",
		};
	}
	if (servicio === "seguro_pickup_particular") {
		return {
			backImg: "carga.jpg",
			titulo: "Mas espacio requiere más protección",
			subtitulo: "Adquiere servicios de protección para tu Pick-up",
			tituloServicio: "Encuentra el mejor seguro para tu Pick-up",
			textOneCol:
				"Así como tu Pick Up te ofrece más espacio y capacidad de carga y peso, el Seguro para Pick Ups ofrece una amplia gama de coberturas que te permitirá proteger al vehículo, a ti como conductor, a tus pasajeros y tus bienes personales que transportes sobre ella. Y sin ser menos importante, tendrás respaldo para solventar los daños que le puedas causar a terceros. ",
			textPone: "¡Existes varios niveles de protección! Según tus necesidades, elige entre:",
			textPtwo:
				"Cobertura Amplia: La protección más completa: te protege ante robo del vehículo, cubre los daños materiales que pueda sufrir tu auto y su reposición o reparación, además de brindarte asistencia legal y vial. Cubre los daños causados a terceros (bienes o personas) y los gastos médicos del conductor y los ocupantes . ",
			textPthree:
				"Cobertura Limitada: Este solamente cubre los daños materiales causados a terceros (bienes o personas), los gastos médicos requeridos para el conductor y los ocupantes, te da respaldo ante robo del vehículo y te otorga asistencia legal y vial.",
			textPfour:
				"Responsabilidad Civil: Es la cobertura mínima y obligatoria requerida para poder circular, la cual te respalda para cubrir los daños causados por tu vehículo a terceros (bienes o personas) ¡Además, podrás complementar tu póliza con las coberturas adicionales que se adapten a tus necesidades y crear tu traje a la medida!",
			textPfive: "",
			textPsix: "",
			textPseven: "",
			textPeight: "",
			textPnine: "",
			servicio: "pick-up particular",
			coberturaOne: "Cobertura amplia",
			coberturaTwo: "Cobertura limitada",
			coberturaThree: "Responsabilidad Civil",
			srvimg: "/images/image-carga-2.png",
		};
	}

	if (servicio === "seguro_hogar") {
		return {
			backImg: "hogar.jpg",
			titulo: "Tu patrimonio es lo más importante",
			subtitulo: "Un seguro de hogar es sinónimo de tranquilidad",
			tituloServicio: "Encuentra el mejor seguro para tu hogar",
			textOneCol:
				"Mantener protegido tu patrimonio de cualquier situación que pueda afectar a tu casa y tus pertenencias es de vital importancia. No importa si el lugar donde vives es propio o rentado, con un Seguro de Hogar estarás cubierto ante incendios, robos, desastres naturales y muchas eventualidades más.",
			textPone: "¡Las coberturas del Seguro de Hogar te darán tranquilidad ante cualquier eventualidad!",
			textPtwo: "Incendios producidos de forma accidental, ya sea causados por un rayo, malfuncionamientos o responsabilidad de terceros. ",
			textPthree: "Terremotos, movimientos telúricos donde tu inmueble resulte dañado. ",
			textPfour: "Explosión de tanques de gas, calentadores, aparatos u otros artefactos. ",
			textPfive: "Robo y/o asalto donde haya evidencia de violencia.",
			textPsix: "Fenómenos Hidrometeorológicos como huracanes, inundaciones, vientos tempestuosos, granizo, y aquellos relacionados con el clima.",
			textPseven: "Responsabilidad Civil para cubrir los daños por el inmueble asegurado a terceros.",
			textPeight: "¡Y mucho más!",
			textPnine: "",
			servicio: "hogar",
			coberturaOne: "Coberturas para dentro y fuera de casa",
			coberturaTwo: "Responsabilidad civil",
			coberturaThree: "Para viviendas propias o rentadas",
			srvimg: "/images/hogars.jpg",
		};
	}
	if (servicio === "seguro_medico") {
		return {
			backImg: "medicos.jpg",
			titulo: "Tu salud es lo más importante",
			subtitulo: "Un seguro de gastos médicos es el medio para salvaguardar tu bienestar",
			tituloServicio: "Encuentra el mejor seguro de gastos médicos",
			textOneCol:
				"Te brinda respaldo y protección económica para hacer frente y poder cubrir los gastos resultantes de enfermedades o accidentes que requieran atención médica mayor, además, cuentas con la posibilidad de asegurar y hacer extensivo los beneficios a tus familiares de línea directa descendiente.",
			textPone: "¿A qué tengo acceso con la contratación de un Seguro de Gastos Médicos Mayores?",
			textPtwo: "Hospitalización: Accede a una gran red hospitalaria para atender y dar seguimiento a tus padecimientos de la mano de médicos certificados. ",
			textPthree: "Intervenciones Quirúrgicas: Ya sean de emergencia o programadas, podrás cubrir el costo de los procedimientos necesarios. ",
			textPfour: "Análisis Clínicos: Para el diagnóstico y seguimiento de los padecimientos reportados. ",
			textPfive: "Medicamentos: Adquiere los medicamentos necesarios para la atención post operatoria y por enfermedades. ",
			textPsix: "¡Y mucho más!",
			textPseven: "",
			textPeight: "",
			textPnine: "",
			servicio: "gastos médicos",
			coberturaOne: "Amplia red de hospitales",
			coberturaTwo: "Beneficios a familiares directos",
			coberturaThree: "Variedad en paquetes y coberturas",
			srvimg: "/images/medico.jpg",
		};
	}
	return {
		texto: "No existe dicho servicio",
		imagen: "Not found",
	};
};

export default function Service({ innerpage, changeCotizar }) {

	const { pageCTX, changePageCTX } = useContext(ContextGlobal);
	const [loadTxt, chLoadTxt] = useState(false);

	const showModalCotizar = (innerpage) => {
		console.log("..:::Pagina actual:::..", innerpage);
		chLoadTxt(true);
		changePageCTX(innerpage);
		M.toast({html:"Espere un momento por favor..."});
		setTimeout(() => {
			let modalCotizar = document.getElementById("modalCotizar");
			let inst = M.Modal.init(modalCotizar, {
				startingTop: "2%",
				endingTop: "5%"
			});
			inst.open();
			chLoadTxt(false);
		}, 750);
	}

	useEffect(() => {
		console.log("Valor del page context", pageCTX);
	}, []);


	let obj = replaceContent(innerpage);
	return (
		<div className="fullwidth back-w pb-5">
			<div className="container-fluid service_backimg"
				style={{
					backgroundImage: `url(/images/${obj.backImg})`,
				}}
			>
				<div className="row">
					<div className="col m12">
						<h2>{obj.titulo}</h2>
						<p>{obj.subtitulo}</p>
					</div>
				</div>
			</div>
			<div className="container gral_info">
				<div className="row">
					<h5 className="st">{obj.tituloServicio}</h5>
					<p>{obj.textOneCol}</p>
				</div>
			</div>

			<div className="container mt-3  text-left">
				<div className="row">
					<div className="col m6">
						<div className="card 	z-depth-3 brd">
							<h5 className="st text-center pt-4 pl-3 pr-3">Cotizar seguro para {obj.servicio}</h5>
							<p className="text-center">Paga mensualmente o de contado</p>
							<img className="full-img mt-3 mb-3" src={obj.srvimg} alt="" />
							<ul className="claims">
								<li className="flex">
									<i className="material-icons mr-2">info</i>
									{obj.coberturaOne}
								</li>
								<li className="flex">
									<i className="material-icons mr-2">info</i>
									{obj.coberturaTwo}
								</li>
								<li className="flex">
									<i className="material-icons mr-2">info</i>
									{obj.coberturaThree}
								</li>
							</ul>
						</div>
					</div>
					<div className="col m6">
						<p>{obj.textPone}</p>
						<p>{obj.textPtwo}</p>

						<p>{obj.texttwo}</p>
						<p>{obj.textPthree}</p>
						<p>{obj.textPfour}</p>
						<p>{obj.textPfive}</p>
						<p>{obj.textPsix}</p>
						<p>{obj.textPseven}</p>
						<p>{obj.textPeight}</p>
						<p>{obj.textPnine}</p>

					</div>
				</div>
			</div>
			<div className="row">
				<div className="col m12 s12 text-center">
					
						<button className="btn" onClick={() => { showModalCotizar(innerpage) }}>
							Cotizar
						</button>
						
				</div>
			</div>
		</div>
	);
}
