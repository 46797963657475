import React, { useEffect, useState } from "react";
import { getServiceIdApi, cancelPremiumApi } from "../../api/Admin/user";
import useAuth from "../../hooks/useAuth";
import CanceledPremium from "./CanceledPremium";

export default function CancelPremium() {
  const { user, setPremium } = useAuth();
  const [reloadUsers, setReloadUsers] = useState(false);
  /*APi premium*/
  const cancelPremium = async () => {
    const response = await getServiceIdApi(user.IdVend);
    const serviceId = response.DataResponse[0].IDService;
    console.log(serviceId);
    const cancelService = await cancelPremiumApi(serviceId);
    setPremium(false);
    setReloadUsers(true);
    console.log(cancelService);
  };

  useEffect(() => {
    setReloadUsers(false);
  }, [reloadUsers]);

  /*fin APi premium*/
  return (
    <>
      <div id="modal-cancel-premium" className="modal">
        <div className="modal-content">
          <div className="flex-center-alw"></div>
          <a href="#" className="modal-close top-left">
            Cerrar
          </a>
          <h5 className="st text-center">Cancelación Click+ Premium</h5>
          <div className="modal-header">
            <img className="full-img" src="/images/back-diamond.jpg" alt="" />
          </div>
          <div className="row mid-pad">
            <h5 className="st text-center">Te vamos a extrañar</h5>
            <p className="small-txt text-center mt-3">Si cancelas tu membresía a premium, la apariencia de tu sitio podría cambiar a los valores predeterminados</p>

            <div className="mt-5 text-center ">
              <button className="waves-effect sq-btn darken-1 white-text modal-trigger  modal-close">No cancelar mi membresía</button>
              <button className="gh-btn mt-3 modal-trigger modal-close" href="#modal-canceled-premium" onClick={cancelPremium}>
                Quiero cancelar mi membresía
              </button>
            </div>
          </div>
        </div>
      </div>
      <CanceledPremium />
    </>
  );
}
