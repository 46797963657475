export const TOKEN = "token";

export const ME_TOKEN = "user_auth_token";

export const URL_AUTH = "https://ws.developmentservices.com.mx/Api3.0/ApiLoginRest/api/login/authenticate/";

export const PREMIUM_API_PATH = "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/services";

export const URL_CONSULTA_LEADS = "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/consulta_leads";

export const URL_CONSULTA_PRODUCTOS = "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/get_productos";

export const URL_CONSULTA_SERVICIOS = "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/services";

export const URL_CANCELA_SERVICIOS = "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/cancel_service";

export const URL_REACTIVA_PREMIUM = "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/reactive_service";

export const URL_CONSULTA_COMPANIAS = "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/get_cias";

export const URL_SWITCH_COMPANIES = "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/cias";

export const URL_USER_DATA = "https://ws.developmentservices.com.mx/Api3.0/ApiVendedor/api/vendedor/Valida_Acceso_X_Token";

export const URL_CONSULTA_USERS = "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/users_plus";

export const PARAMS_PREFIX = "t=";

export const LOCAL_URL = "localhost:3000";

export const ADMIN_URL = "clickplus.clicksegurosvip.com";

export const PUBLIC_URL = "clickseguros.online";
