import React, { useEffect, useState } from "react";
import MainPortal from "../customsite/MainPortal";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { getAccessToken } from "../../api/sessionInit";
import { getUserDataFromFirebase } from "../../api/firebaseApi";
import { useParams, Redirect } from "react-router-dom";
import db from "../../firebase/firebaseConfig";

const WebSite = (props) => {
  const { subdomain } = props;
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [dataAgnFb, changeDataAgnFb] = useState({});
  const { nameurl } = useParams();
  const [isproduction, chproduction] = useState(true);
  const [publicToken, setPublicToken] = useState(null);

  //Obtener toda la información en firebase

  const acceptConditions = () => {};
  const [editServ, changeServ] = useState(false);
  const [editCard, changeCard] = useState(false);
  const [editCedula, changeCedula] = useState(false);

  const [products, changeProducts] = useState([
    {
      id: "item-1",
      name: "Seguro para tu auto",
      icon: "directions_car",
      title: "Cotizar seguro para autos",
      precio: "$3,000",
      tiposervicio: "auto",
      cobertura: "con cobertura de responsabilidad civil",
      detalles: ["Robo total", "RC por daños a terceros", "Defensa legal / jurídica", "Gastos médicos ocupantes"],
      image: "/images/autos-1.jpg",
      status: 1,
      innerpage: "seguro_autos",
    },
    {
      id: "item-2",
      name: "Seguro para motos",
      icon: "motorcycle",
      title: "Cotizar seguro para motocicletas",
      precio: "$3,000",
      tiposervicio: "motos",
      cobertura: "con cobertura de responsabilidad civil",
      detalles: ["Cobertura amplia", "Cobertura limitada", "Responsabilidad Civil"],
      image: "/images/motos-1.jpg",
      status: 1,
      innerpage: "seguro_motos",
    },
    {
      id: "item-3",
      name: "Seguro para Pickup",
      icon: "local_shipping",
      title: "Cotizar seguro para pickup",
      precio: "$3,000",
      tiposervicio: "carga",
      cobertura: "con cobertura de responsabilidad civil",
      detalles: ["Cobertura amplia", "Cobertura limitada", "Responsabilidad Civil"],
      image: "/images/carga-1.jpg",
      status: 1,
      innerpage: "seguro_pickup_particular",
    },

    {
      id: "item-4",
      name: "Seguro Médico",
      icon: "local_hospital",
      title: "Cotizar seguro Médico",
      precio: "$12,000",
      tiposervicio: "medico",
      cobertura: "con cobertura de responsabilidad civil",
      detalles: ["Amplia red de hospitales", "Beneficios a familiares directos", "Variedad en paquetes y coberturas"],
      image: "/images/medicos-1.jpg",
      status: 1,
      innerpage: "seguro_medico",
    },
    {
      id: "item-5",
      name: "Seguro Hogar",
      icon: "home",
      title: "Cotizar seguro de Hogar",
      precio: "$12,000",
      tiposervicio: "hogar",
      cobertura: "con cobertura de responsabilidad civil",
      detalles: ["Coberturas para dentro y fuera de casa", "Responsabilidad civil", "Para viviendas propias o rentadas"],
      image: "/images/hogar-1.jpg",
      status: 1,
      innerpage: "seguro_hogar",
    },
  ]);

  const [brandsList, setBrandsList] = useState([
    { id: 1, active: 1, name: "Quálitas", img: "images/qualitas.svg" },
    { id: 2, active: 1, name: "Sura", img: "images/sura.svg" },
    { id: 3, active: 1, name: "Mapfre", img: "images/mapfre.svg" },
    { id: 4, active: 1, name: "Metlife", img: "images/metlife.svg" },
    { id: 5, active: 1, name: "Sura", img: "images/sura.svg" },
  ]);

  const [styleclass, changeClassStyle] = useState({
    bgPrimary: "back-bl",
    bgSecondary: "back-sec",
    bgBox: "back-lbl",
    btn: "btn-blu",
    fonttype: "inherit", //"font-times","font-monserrat",
    textheader: "",
    block_title: "Aquí puede encontrar los principales ramos que trabajo",
    block_description: "Si no encuentras el seguro que necesitas, contáctame dando click en el botón de abajo",
    imagesproduct: "/images/image-product.png",
    products: products,
    brands: brandsList,
    urlimage: "/images/logo-h.png",
    contratame: "Contratar el seguro a tu medida es muy sencillo conmigo. Contáctame y te ayudaré a encontrar la mejor opción para ti",
    cedula: "S2147QWRS",
    contitle: "Mucho gusto soy",
    condescription: "tu agente de seguros",
    conimage: "/images/man.jpg",
  });

  // return (
  //     <>
  //         {(nameurl !== "") ?
  //             <MainPortal
  //                 styleclass={styleclass}
  //                 acceptConditions={acceptConditions}
  //                 dataAgnFb={dataAgnFb}
  //                 changeClassStyle={changeClassStyle}
  //                 changeServ={changeServ}
  //                 changeCard={changeCard}
  //                 changeCedula={changeCedula}
  //             ></MainPortal>
  //             :
  //             <Redirect to="/" />
  //         }

  //     </>
  // );

  const [loadedsite, changeLoader] = useState(false);
  const [message, changeMessage] = useState("Espera un momento por favor ...");
  const MessageView = () => {
    return (
      <div className="center col m4 s12 offset-m4">
        <h5>{message}</h5>
      </div>
    );
  };

  useEffect(() => {
    (async () => {
      const response = await getAccessToken();
      setPublicToken(response.Token);
      console.log("version 1.0.3");
    })();
  }, []);

  useEffect(() => {
    if (subdomain != undefined) {
      (async () => {
        const response = await getUserDataFromFirebase(subdomain);
        console.log(response);
        setFirebaseUser(response);
        console.log(response);
        changeDataAgnFb(response);
        changeClassStyle(response.user_site);
        changeProducts();
        changeLoader(true);
      })();
    }
  }, []);

  useEffect(() => {
    //console.log("result=>", nameurl);
    if (!subdomain) {
      const q = query(collection(db, "cp_agents"), where("agent_url", "==", nameurl));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        //const cities = [];

        if (querySnapshot.empty) {
          changeMessage("Ups! El sitio que intentas acceder no existe.");
          console.log(subdomain);
        } else {
          querySnapshot.forEach((doc) => {
            let { user_site } = doc.data();
            changeDataAgnFb(doc.data());
            changeClassStyle(user_site);
            changeLoader(true);
          });
        }
      });
    }

    //unsubscribe();
  }, []);

  return loadedsite ? (
    <MainPortal
      isproduction={isproduction}
      styleclass={styleclass}
      acceptConditions={acceptConditions}
      dataAgnFb={dataAgnFb}
      changeClassStyle={changeClassStyle}
      changeServ={changeServ}
      changeCard={changeCard}
      changeCedula={changeCedula}
      nameurl={nameurl}
      firebaseUser={firebaseUser}
      subdomain={subdomain}
      publicToken={publicToken}
      products={products}
    ></MainPortal>
  ) : (
    <MessageView message={"Espere por favor"}></MessageView>
  );
};

export default WebSite;
