import React, { useState } from "react";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import storage from "../../firebase/firebaseConfigStorage";

const MenuFooterCard = ({ styleclass, changeClassStyle, changeFlag }) => {
  const [contactosaludo, changeSaludo] = useState(styleclass.contitle === undefined ? "Mucho gusto soy" : styleclass.contitle);
  const [contactodescripcion, changeDescripcion] = useState(styleclass.condescription === undefined ? "su agente de seguros" : styleclass.condescription);
  const [imageAsFile, setImageAsFile] = useState(styleclass.conimage === undefined ? "/images/man.jpg" : styleclass.conimage);
  const [blockButton, chBlock] = useState(false);

  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    setImageAsFile((imageFile) => image);
  };

  const handleInpTitle = (e) => {
    changeSaludo(e.target.value);
  };
  const handleInpDes = (e) => {
    changeDescripcion(e.target.value);
  };

  const handleFireBaseUpload = (e) => {
    chBlock(!blockButton);
    e.preventDefault();
    console.log("start of upload");

    if (imageAsFile === "/images/man.jpg") {
      changeClassStyle({
        ...styleclass,
        contitle: contactosaludo,
        condescription: contactodescripcion,
        //conimage: imageAsFile
      });
      return;
    } else {
      console.log(storage);
      //Eliminar antes de subir ...
      const imageRef = ref(storage, "imagescontact/" + imageAsFile.name);
      //const imageRef = ref(storage, 'imageslogo/' + uuidv4());
      uploadBytesResumable(imageRef, imageAsFile)
        .then((snapshot) => {
          console.log("Uploaded", snapshot.totalBytes, "bytes.");
          console.log("File metadata:", snapshot.metadata);
          // Let's get a download URL for the file.
          getDownloadURL(snapshot.ref).then((url) => {
            console.log("File available at", url);
            // ...
            changeClassStyle({
              ...styleclass,
              contitle: contactosaludo,
              condescription: contactodescripcion,
              conimage: url,
            });
          });
          setImageAsFile(""); //clear the input file
          chBlock(false);
        })
        .catch((error) => {
          console.error("Upload failed", error);
          // ...
        });
    }
  };

  return (
    <div className="section">
      <button className="link-back" onClick={() => changeFlag({ isservice: false, iscard: false, iscedula: false })}>
        <h4 className="pad-s">
          <i className="ico-ftl ico-circ wh icon-facebook">&#xf104;</i>
          Regresar al configurador
        </h4>
      </button>
      <h3 className="st center-align">Sección de contacto</h3>
      <div className="menu-pad">
        <p className="mb-3">Este texto se mostrará a tus clientes </p>

        <div className="row border mb-4">
          <form onSubmit={handleFireBaseUpload} action="">
            <div className="input-field col m12 s12">
              <label className="active">Título</label>
              <input type="text" value={contactosaludo} onChange={handleInpTitle} />
            </div>
            <div className="input-field col m12 s12">
              <label className="active">Descripción</label>
              <input type="text" value={contactodescripcion} onChange={handleInpDes} />
            </div>
            <div className="clr"></div>
            <div className="im-field">
              <div className="file-field input-field">
                <div className="btn fil">
                  <span>Imagen</span>
                  <input type="file" onChange={handleImageAsFile} />
                </div>
                <div className="file-path-wrapper">
                  <input className="file-path validate" type="text" placeholder="Sube una imagen" />
                </div>
              </div>
              <div className="clr"></div>
            </div>

            <div className="col m12 s12 center">
              <p>{blockButton ? "Espere un momento por favor" : ""}</p>
              <button type="submit" className="btn btn-edit-srv normal-text" disabled={blockButton}>
                Actualizar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MenuFooterCard;
