import React, { useState, useEffect, useContext } from "react";
import { ContextGlobal } from "../../../contexts/ContextGlobal";
import db from "../../../firebase/firebaseConfig";
//import { collection, addDoc, query, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";

const FormBlock4 = (props) => {
  const { setStepCard } = useContext(ContextGlobal);

  const [agent_redes, changeRedes] = useState([{ agent_url_social: "", category: "", icono: "" }]);
  /*
    const [agent_redes, changeRedes] = useState(
        { agent_url_social: "", category: "", icono: "" }
    );
    */

  const localDataAgent = localStorage.getItem("dataagent") ? JSON.parse(localStorage.getItem("dataagent")) : [];

  //const [agent_data, changeAgentData] = useState({}); //Obtiene todo los datos from Firebase
  const [agent_data_local, changeDataAgentLocal] = useState(localDataAgent); //Cambios locales

  useEffect(() => {
    //console.log("From local storage - step 4", localDataAgent);
    const unsub = onSnapshot(doc(db, "cp_agents", localDataAgent.document), (doc) => {
      console.log("Current data vc4: ", doc.data());
      const datatmp = doc.data();
      if (datatmp.redes_agente != "undefined" && datatmp.redes_agente != null) {
        changeRedes(datatmp.redes_agente);
      }
    });
  }, []);

  const handleInput = (e, index) => {
    const { name, value } = e.target;
    //if (name === "category") validateIcon(value);
    //if (name === "agent_url_social") setupUrl(value);

    //just one record
    //const list = { ...agent_redes, [name]: value, icono: (name === "category") ? validateIcon(value) : agent_redes.icono };

    const list = [...agent_redes];
    console.log("Valor de select => ", value);
    list[index][name] = value; //actualizamos el dato
    list[index]["icono"] = name === "category" ? validateIcon(value) : agent_redes[index].icono;
    list[index]["agent_url_social"] = name === "category" ? setupUrl(value) : agent_redes[index].agent_url_social;
    
    changeRedes(list);
  };

  const handleAddInput = () => {
    //changeAgentPhoneList([...agent_phones,{agent_phone: "", agent_phone_type: ""}]);
    const list = [...agent_redes];
    list.push({ agent_url_social: "", category: "", icono: "" });
    changeRedes(list);
  };

  const handleRemoveInput = (index) => {
    const list = [...agent_redes];
    list.splice(index, 1);
    changeRedes(list);
  };

  const validateIcon = (name) => {
    if (name === "Facebook") return "/images/face.svg";

    if (name === "Instagram") return "/images/inst.svg";

    if (name === "Linkedin") return "/images/link.svg";
  };

  const setupUrl = (name) => {
    if (name === "Facebook") return "https://facebook.com/";

    if (name === "Instagram") return "https://instagram.com/";

    if (name === "Linkedin") return "https://www.linkedin.com/";
    
    return "";
  }

  const sendForm = (e) => {
    e.preventDefault();
    /*
        try {
            const res =  addDoc(collection(db, "cp_agents"),{ redes_agente: agent_redes }).then( (response) =>{
                console.log("Document written with ID: ", response.id);
                //if this all correct, next step
                props.stepFormChange(5);
            }).catch( (error) => {
                console.log(error)
            })
            console.log(res);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
        */
    const userRef = doc(db, "cp_agents", localDataAgent.document);
    updateDoc(userRef, {
      //...agent_data,
      step: 5,
      redes_agente: agent_redes,
      iscomplete: true,
    })
      .then(() => {
        console.log("Se ha actualizado - paso 4");
        props.stepFormChange(5);
        //Actualizamos la información
        localStorage.setItem(
          "dataagent",
          JSON.stringify({
            ...agent_data_local,
            step: 5,
            iscomplete: true,
          })
        );
        setStepCard(5, true); //Setting in the main view
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });

    /* post */

    //Send user info is Free => ""
  };
  /*
    useEffect(() => {
        console.log(agent_redes);
    });
    */
  return (
    <div className="col m12 s12">
      <h5 className="st text-center">Redes sociales</h5>
      <p className="big-marg">Comparte tus redes sociales con tus clientes</p>
      <form onSubmit={sendForm}>
        <input type="hidden" name="stepinput" value="5" readOnly />

        {agent_redes.map((item, index) => {
          return (
            <div className="row" key={index}>
              <div className="input-field col m1 s12">
                <img src={item.icono} alt="" />
              </div>

              <div className="input-field col m5 s12">
                <label className="active">Selecciona la red social</label>
                <select className="browser-default" required={true} name="category" value={item.category} onChange={(e) => handleInput(e, index)}>
                  <option value="">Seleccionar</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Linkedin">Linkedin</option>
                </select>
              </div>
              <div className="col m5 s12">
                <label htmlFor={"item" + index} className="active"> Completa el enlace de tu red social</label>
                <input type="text" className="validate" id={"item" + index} name="agent_url_social" value={item.agent_url_social} required={true} onChange={(e) => handleInput(e, index)} />
                
              </div>
              <div className="col m12 s12 text-center rt-sp mt-5 mb-4">
                {index > 0 ? (
                  <span className="pointer btn red mr-4" onClick={() => handleRemoveInput(index)}>
                    Eliminar
                  </span>
                ) : (
                  ""
                )}
                {agent_redes.length - 1 === index && (
                  <span className="pointer btn-dk z-depth-3" onClick={handleAddInput}>
                    Agregar otra red social +
                  </span>
                )}
              </div>
            </div>
          );
        })}

        <div className="input-field col m12 s12 text-center bt-sp mt-5">
          <button type="submit" className="waves-effect sq-btn darken-1 white-text" id="buttonBlock4">
            Continuar
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormBlock4;
