import React, { useState, useEffect, useContext } from "react";
import VirtualCardEdit from "../user/VirtualCardEdit";
import M from "materialize-css/dist/js/materialize.min.js";
const CardHome = (props) => {
  const { stateCard, dataAgnFb, setReloadCompanies, stepFormChange } = props;
  const [editarOpt, changeEditarOpt] = useState(1);

  useEffect(() => {
    //M.AutoInit();
    //console.log("Paso formulario", stateCard);
  });

  const showModalEdit = () => {
    const showcard = document.getElementById("modalVCE");
    const modal = M.Modal.init(showcard, {});
    modal.open();
  };

  const ShowFirstTime = () => {
    return <></>;
  };

  return (
    <div className="card-click z-depth-2">
      <div id="modalComenzar" className="modal">
        <div className="modal-content">
          <div className="row">
            <div className="flex-btw">
              <div className="col m12 s12">
                <img src="/images/img-card-first.png" alt="card-finish" className="responsive-img" />
                <h5 className="st text-center">¡Vamos a configurar tu información!</h5>
                <h5>Un solo contacto</h5>
                <span>Permite que tus prospectos puedan contactarte en cualquier medio que ellos quieran de una forma sencilla</span>
                <h5>Comparte tu información</h5>
                <span>Toda tu información en un solo link</span>
                <br />
                <br />
                <button className="waves-effect sq-btn darken-1 white-text modal-trigger modal-close " href="#modal1">
                  Comencemos
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {stateCard.step === 0 && stateCard.iscomplete === false ? (
        <div className="row">
          <div className="col  s12">
            <h5 className="st flex-ico">
              <span className="material-icons r-ico i-red">person</span>
              Mi tarjeta virtual
            </h5>
          </div>
          <div className="col m12 s12">
            <div className="short-img">
              <img src="/images/planet.svg" alt="planet" />
            </div>
            <p className="text-center">Agrega tu información básica para que tus clientes puedan contactarte de una forma fácil</p>
          </div>
          <div className="col m12 s12 text-center">
            <div className="forbtn">
              {/* #Validar que paso en este modal modalComenzar */}
              <a
                className="sc-btn"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  stepFormChange(1);
                  const showcard = document.getElementById("modalComenzar");
                  const modal = M.Modal.init(showcard, {});
                  modal.open();
                  return false;
                }}
              >
                Comenzar
              </a>
            </div>
          </div>
        </div>
      ) : stateCard.step >= 1 && stateCard.step < 6 && stateCard.iscomplete === false ? (
        <div className="row">
          <div className="col m12 s12">
            <h5 className="st">Mi tarjeta virtual</h5>
          </div>
          <div className="col m12 s12">
            <p className="st dt-sp">Termina de configurar tu tarjeta virtual</p>
            <p className="dt-sp">
              Ayuda a tus clientes a tener tu contacto de una forma sencilla.
              <br />
              Tu tarjeta estará visible únicamente cuando tenga toda tu información
            </p>
          </div>
          <div className="col m12 s12">
            <div className="row text-center rt-sp">
              <div className="col m3 s3 ">
                <span>
                  {/* Completo */}
                  {stateCard.step >= 2 ? <i className="material-icons cyann">lens</i> : <i className="material-icons cyann">panorama_fish_eye</i>}
                  {/* Incompleto */}
                  {/* <i className="material-icons">panorama_fish_eye</i> */}
                </span>
              </div>
              <div className="col m3 s3">{stateCard.step >= 3 ? <i className="material-icons cyann">lens</i> : <i className="material-icons cyann">panorama_fish_eye</i>}</div>
              <div className="col m3 s3">{stateCard.step >= 4 ? <i className="material-icons cyann">lens</i> : <i className="material-icons cyann">panorama_fish_eye</i>}</div>
              <div className="col m3 s3">{stateCard.step >= 5 ? <i className="material-icons cyann">lens</i> : <i className="material-icons cyann">panorama_fish_eye</i>}</div>
            </div>
            <div className="row ">
              {dataAgnFb?.agent_url !== undefined && dataAgnFb?.agent_url !== null ? (
                <div className="col m12 s12 valign-wrapper wht">
                  <i className="material-icons prefix ico-grey">language</i>
                  <span>{dataAgnFb?.agent_url}</span>
                </div>
              ) : (
                ""
              )}
              {dataAgnFb?.agent_name !== undefined && dataAgnFb?.agent_name !== null ? (
                <div className="col m12 s12 valign-wrapper wht">
                  <i className="material-icons prefix ico-grey">account_circle</i>
                  <span>{dataAgnFb?.agent_name}</span>
                </div>
              ) : (
                ""
              )}

              {dataAgnFb?.agent_email !== undefined && dataAgnFb?.agent_email !== null ? (
                <div className="col m12 s12 valign-wrapper wht">
                  <i className="material-icons prefix ico-grey">mail_outline</i>
                  <span>{dataAgnFb?.agent_email}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col m12 s12 text-center">
            <a
              className="sc-btn"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                console.log("Status de la tarjeta", stateCard);
                if (dataAgnFb.user_site == undefined) {
                  console.log("::: No se ha finalizado la tarjeta :::");
                  const showcard = document.getElementById("modal1");
                  const modal = M.Modal.init(showcard, {});
                  modal.open();
                } else {
                  console.log("::: Se finalizó la tarjeta  :::");
                  const showcard = document.getElementById("modalVCE");
                  const modal = M.Modal.init(showcard, {});
                  modal.open();
                }
              }}
            >
              Continuar
            </a>
          </div>
        </div>
      ) : stateCard.step >= 1 && stateCard.step < 6 && stateCard.iscomplete === true ? (
        <>
          <div className="row flex-ico">
            <div className="col m10">
              <h5 className="st flex-ico mb-4">
                <span className="material-icons r-ico i-red">person</span>
                Mi tarjeta virtual
              </h5>
            </div>
            <div className="col m2 right-align">
              {dataAgnFb.agent_phones ? (
                <a
                  href={`https://api.whatsapp.com/send?phone=521${dataAgnFb?.agent_phones[0].agent_phone}&text=Hola%20mi%20nombre%20es%20${dataAgnFb?.agent_name}%2C%20te%20invito%20a%20ver%20mi%20sitio%20web%20https://${dataAgnFb?.agent_url}.clickseguros.online`}
                  target="_blank"
                >
                  <span className="material-icons circ-blue i-blue z-depth-2">share</span>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row scroll-on">
            {dataAgnFb?.agent_url !== "undefined" && dataAgnFb?.agent_url !== null ? (
              <>
                <div className="col m12 s12 valign-wrapper wht">
                  <i className="material-icons prefix ico-grey">language</i>
                  <span>{dataAgnFb?.agent_url}.clickseguros.online</span>
                  <div className="sh-hov">
                    <a
                      className="btn-dk z-depth-3"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        changeEditarOpt(1);
                        showModalEdit();
                      }}
                    >
                      Editar <span className="material-icons circ-little">border_color</span>
                    </a>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {dataAgnFb?.agent_name !== "undefined" && dataAgnFb?.agent_name !== null ? (
              <>
                <div className="col m12 s12 valign-wrapper wht">
                  <i className="material-icons prefix ico-grey">account_circle</i>
                  <span>{dataAgnFb?.agent_name}</span>
                  <div className="sh-hov">
                    <a
                      className="btn-dk z-depth-3"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        changeEditarOpt(1);
                        showModalEdit();
                      }}
                    >
                      Editar <span className="material-icons circ-little">border_color</span>
                    </a>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {dataAgnFb?.agent_email !== undefined && dataAgnFb?.agent_email !== null ? (
              <>
                <div className="col m12 s12 valign-wrapper wht">
                  <i className="material-icons prefix ico-grey">mail_outline</i>
                  {/* <span>{dataAgnFb?.agent_email}</span> */}
                  <span>{dataAgnFb?.agent_url}@clickseguros.online</span>
                  <div className="sh-hov">
                    <a
                      className="btn-dk z-depth-3"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        changeEditarOpt(2);
                        showModalEdit();
                      }}
                    >
                      Editar <span className="material-icons circ-little">border_color</span>
                    </a>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {dataAgnFb?.agent_phones != undefined && dataAgnFb?.agent_phones != null ? (
              <>
                <div className="col m12 s12 valign-wrapper wht">
                  <i className="material-icons prefix ico-grey">phone_android</i>
                  <span>{dataAgnFb?.agent_phones[0].agent_phone}</span>
                  <div className="sh-hov">
                    <a
                      className=" btn-dk z-depth-3"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        changeEditarOpt(2);
                        showModalEdit();
                      }}
                    >
                      Editar <span className="material-icons circ-little">border_color</span>
                    </a>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {dataAgnFb?.agent_schedule != undefined && dataAgnFb?.agent_phones != null ? (
              <>
                <div className="col m12 s12 valign-wrapper wht">
                  <i className="material-icons prefix ico-grey">date_range</i>
                  <span>{dataAgnFb.agent_schedule.horario ? dataAgnFb.agent_schedule.horario : "Sin definir"}</span>
                  <div className="sh-hov">
                    <a
                      className=" btn-dk z-depth-3"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        changeEditarOpt(3);
                        showModalEdit();
                      }}
                    >
                      Editar <span className="material-icons circ-little">border_color</span>
                    </a>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {dataAgnFb?.redes_agente != undefined && dataAgnFb?.agent_phones != null ? (
              <>
                {dataAgnFb.redes_agente.length > 0 ? (
                  <>
                    {dataAgnFb.redes_agente.map((item, index) => {
                      return (
                        <div key={index}>
                          <div className="col m12 s12 valign-wrapper wht">
                            <div className="red-ico ico-grey">
                              <img src={item.icono} alt="" />
                            </div>
                            {item.agent_url_social}
                            <div className="sh-hov">
                              <a
                                className=" btn-dk z-depth-3"
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  changeEditarOpt(4);
                                  showModalEdit();
                                }}
                              >
                                Editar <span className="material-icons circ-little">border_color</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
      {/* Cuando no ha terminado la tarjeta */}
      {/* Tarjeta finalizada */}
      {/* <Switch><Route path="/"  exact={true} component={VirtualCard}></Route></Switch> */}
      {dataAgnFb?.agent_url !== undefined && dataAgnFb?.agent_url !== null ?
        <VirtualCardEdit editarOpt={editarOpt} changeEditarOpt={changeEditarOpt} setReloadCompanies={setReloadCompanies}></VirtualCardEdit> : ""}
    </div>
  );
};

export default CardHome;
