import React, { useState, useEffect } from "react";
import FormAgentCard from "./FormAgentCard";
import FormDatosContacto from "./FormDatosContacto";
import M from "materialize-css/dist/js/materialize.min.js";

const FormBudgetHogar = ({ formStepContact, changeFSC, changeCotizar, styleclass, dataAgnFb, subdomain, firebaseUser, publicToken }) => {
  const concept = { name: "Seguro Hogar", codename: "segurohogar" };

  const [hogar, changeHogar] = useState({
    acercahogar: "Soy dueño y vivo ahí",
    codigopostal: "",
    tipoconstruccion: "",
    numpisos: "",
    numsotanos: "",
    valorpropiedad: "",
    valorpertenencias: "",
  });

  const [contactMethod, setContactMethod] = useState({
    name: "",
    email: "",
    phone: "",
    contactby: "",
  });

  const [forms, changeForms] = useState(1);

  const FormMain = ({ style, hogar, changeHogar }) => {
    const [tmphogar, changetmpHogar] = useState(hogar);
    const [opthogar, changeopt] = useState(1);

    const handleLugar = (info, type) => {
      changetmpHogar({
        ...tmphogar,
        acercahogar: info,
      });
      changeopt(type);
    };

    const handleInput = (e, nameinp) => {
      const hogar = { ...tmphogar };

      if (nameinp == "codigopostal" || nameinp == "numpisos" || nameinp == "numsotanos") {
        e.target.value = setNumber(e.target.value);
      }

      hogar[nameinp] = e.target.value;
      changetmpHogar(hogar);
    };

    const setNumber = (numero) => {
      let nospace = numero.replace(/\s/g, "");
      let nonumbers = nospace.replace(/[^0-9]+/g, "");
      return nonumbers;
    };

    const onSubmitForm = () => {
      console.log("Datos del hogar", tmphogar);

      if (tmphogar.codigopostal == "") {
        M.toast({ html: "Ingresa una código postal" });
        return false;
      }
      if (tmphogar.tipoconstruccion == "") {
        M.toast({ html: "Ingresa el Tipo de construcción" });
        return false;
      }
      if (tmphogar.numpisos == "") {
        M.toast({ html: "Ingresa el número de pisos" });
        return false;
      }
      if (tmphogar.numsotanos == "") {
        M.toast({ html: "Ingresa el número de sótanos" });
        return false;
      }
      if (tmphogar.acercahogar == "Soy dueño y vivo ahí") {
        if (tmphogar.valorpertenencias == "") {
          M.toast({ html: "Ingresa el valor de tus pertenencias" });
          return false;
        }
      }
      if (tmphogar.valorpropiedad == "") {
        M.toast({ html: "Ingresa el valor de tu propiedad" });
        return false;
      }

      //return false;
      changeHogar(tmphogar);
      changeForms(2);
    };

    useEffect(() => {
      console.log("Datos hogar", tmphogar);
    });

    //VALIDACIONES

    return (
      <div className={`row ${style}`}>
        <div className="col s12 m12">
          <div className="card radius-card no-padding">
            <div className="card-content no-padding ">
              <div className="row">
                <div className="col s12 m12">
                  <div className="card radius-card mt-0 p-3">
                    <h3 className="st">Proporciona la siguiente información</h3>
                    <p>Esta información es necesaria para poder ofrecerte una cotización a tu medida</p>
                  </div>
                </div>
                <div className="col s12 m12">
                  <div className="col s12 m12 mb-4">
                    <h3 className="st">Sobre el lugar</h3>
                  </div>
                  <div className="col s12 m3">
                    <button
                      className={`dark-btn ${opthogar === 1 ? "active-btn" : ""}`}
                      onClick={() => {
                        handleLugar("Soy dueño y vivo ahí", 1);
                      }}
                    >
                      Soy dueño y vivo ahí
                    </button>
                  </div>
                  <div className="col s12 m3">
                    <button
                      className={`dark-btn ${opthogar === 2 ? "active-btn" : ""}`}
                      onClick={() => {
                        handleLugar("Soy dueño y no vivo ahí", 2);
                      }}
                    >
                      Soy dueño y no vivo ahí
                    </button>
                  </div>
                  <div className="col s12 m3">
                    <button
                      className={`dark-btn ${opthogar === 3 ? "active-btn" : ""}`}
                      onClick={() => {
                        handleLugar("Rento la vivienda", 3);
                      }}
                    >
                      Rento la vivienda
                    </button>
                  </div>
                  <div className="col s12 m3">
                    <p></p>
                  </div>
                </div>

                <div className="col s12 m12">
                  <div className="pad-adj mt-5">
                    Si tienes pertenencias que deseas asegurar en esa propiedad por favor selecciona la opcion “soy dueño y vivo ahi” si no es tu caso continua con este formulario
                  </div>

                  <div className="col s12">
                    <h3 className="st mt-5 mb-5">Completa por favor la siguiente información</h3>
                    <div className="col m3">
                      <label htmlFor="dir_1">Código postal donde está la vivienda</label>
                      <input
                        className="rounded-input"
                        id="dir_1"
                        type="text"
                        maxLength={5}
                        name="codigopostal"
                        value={tmphogar.codigopostal}
                        onChange={(e) => {
                          handleInput(e, "codigopostal");
                        }}
                      />
                    </div>
                  </div>
                  <div className="col s12 m12 mt-5">
                    <div className="col s12 m3">
                      <label htmlFor="build_type">Tipo de construcción</label>
                      <input
                        className="rounded-input"
                        id="build_type"
                        type="text"
                        name="tipoconstruccion"
                        value={tmphogar.tipoconstruccion}
                        onChange={(e) => {
                          handleInput(e, "tipoconstruccion");
                        }}
                      />
                    </div>
                    <div className="col s12 m3">
                      <label htmlFor="build_floor">Número de pisos</label>
                      <input
                        className="rounded-input"
                        id="build_floor"
                        type="text"
                        name="numpisos"
                        maxLength={2}
                        value={tmphogar.numpisos}
                        onChange={(e) => {
                          handleInput(e, "numpisos");
                        }}
                      />
                    </div>
                    <div className="col s12 m3">
                      <label htmlFor="build_under">Número de sótanos</label>
                      <input
                        className="rounded-input"
                        id="build_under"
                        type="text"
                        name="numsotanos"
                        maxLength={1}
                        value={tmphogar.numsotanos}
                        onChange={(e) => {
                          handleInput(e, "numsotanos");
                        }}
                      />
                    </div>
                  </div>
                  <div className="col s12 m12 mt-5">
                    {opthogar === 1 || opthogar === 3 ? (
                      <div className="col s12 m6">
                        {}
                        <label htmlFor="build_value">¿Cuál es el valor de tus pertenencias?</label>
                        <input
                          className="rounded-input"
                          id="build_value"
                          type="text"
                          name="valorpertenencias"
                          maxLength={10}
                          value={tmphogar.valorpertenencias}
                          onChange={(e) => {
                            handleInput(e, "valorpertenencias");
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {opthogar !== 3 ? (
                      <div className="col s12 m6">
                        <label htmlFor="build_value">¿Cuál es el valor de tu propiedad?</label>
                        <input
                          className="rounded-input"
                          id="build_value"
                          type="text"
                          name="valorpropiedad"
                          value={tmphogar.valorpropiedad}
                          onChange={(e) => {
                            handleInput(e, "valorpropiedad");
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col s12 m12 text-center pt-4 pb-4">
                  <button className={styleclass.bgPrimary + " cotiza"} onClick={onSubmitForm}>
                    Enviar información
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const FormRes = ({ hogar, changeForms, style }) => {
    return (
      <div className={`col s12 m12 ${style}`}>
        <div className="card radius-card p-3">
          <h3 className="st">
            Estas cotizando{" "}
            <button
              className="btn-borblub"
              onClick={() => {
                changeForms(1);
              }}
            >
              Editar información
            </button>
          </h3>
          <p>
            <span>
              Acerca del hogar: <b>{hogar.acercahogar}</b>
            </span>{" "}
            |
            <span>
              Código Postal: <b>{hogar.codigopostal}</b>
            </span>{" "}
            |
            <span>
              Tipo de construcción: <b>{hogar.tipoconstruccion}</b>
            </span>{" "}
            |
            <span>
              No. pisos: <b>{hogar.numpisos}</b>
            </span>{" "}
            |
            <span>
              No. sotanos: <b>{hogar.numsotanos}</b>
            </span>{" "}
            |
            <span>
              Valor de la propiedad: <b>{hogar.valorpropiedad}</b>
            </span>{" "}
            |
            <span>
              Valor de las pertenencias: <b>{hogar.valorpertenencias}</b>
            </span>
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    M.AutoInit();
  }, []);

  return (
    <>
      <FormMain style={forms === 1 ? "show" : "hidden"} hogar={hogar} changeHogar={changeHogar} changeForms={changeForms} />
      <FormRes style={forms === 2 ? "show" : "hidden"} hogar={hogar} changeHogar={changeHogar} changeForms={changeForms} />
      <FormDatosContacto
        style={forms === 2 ? "show" : "hidden"}
        contactMethod={contactMethod}
        setContactMethod={setContactMethod}
        changeForms={changeForms}
        data={hogar}
        concept={concept}
        styleclass={styleclass}
        subdomain={subdomain}
        firebaseUser={firebaseUser}
        publicToken={publicToken}
        dataAgnFb={dataAgnFb}
      />
      <FormAgentCard
        style={forms === 3 ? "show" : "hidden"}
        changeFSC={changeFSC}
        changeForms={changeForms}
        changeCotizar={changeCotizar}
        formStepContact={formStepContact}
        styleclass={styleclass}
        dataAgnFb={dataAgnFb}
      />
    </>
  );
};

export default FormBudgetHogar;
