import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const SimpleSlider = (props) => {
  const { brands } = props;

  const totalActive = () => {
    let counter = 0;
    for (let index = 0; index < brands.length; index++) {
      if (brands[index].active) {
        counter++;
      }
    }
    return counter;
  };
  const counter = totalActive();

  const settings = {
    dots: false,
    infinite: true,
    //centerMode: true,
    speed: 500,
    slidesToShow: counter <= 4 ? counter : counter - 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };
  return (
    <Slider {...settings}>
      {brands
        .filter((item) => item.Status == true)
        .map((item, index) => {
          if (item.active === 1) {
            return (
              <div className="brand-logo" key={index}>
                <img className="full-img" src={item.Logo} alt={index} key={index} />
              </div>
            );
          }
        })}
    </Slider>
  );
};

export default SimpleSlider;
