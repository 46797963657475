import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
/*
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
  appId: process.env.REACT_APP_FIREBASE_appId
};
*/
const firebaseConfig = {
  apiKey: "AIzaSyBKp9aoOcpPxIXcXL3N_rMQdRfKHjdNcMw",
  authDomain: "clickplus-2c2d6.firebaseapp.com",
  projectId: "clickplus-2c2d6",
  storageBucket: "clickplus-2c2d6.appspot.com",
  messagingSenderId: "55844328865",
  appId: "1:55844328865:web:846368d7876c34700de7e0",
  measurementId: "G-0XFHEXLCJW",
};
// Initialize Firebase
initializeApp(firebaseConfig);
const db = getFirestore();

export default db;
