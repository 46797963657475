import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { NavLink, Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

import { ContextGlobal } from "../../contexts/ContextGlobal";
import { PARAMS_PREFIX } from "../../utils/constants";
import { getMeApi } from "../../api/sessionInit";
import { getUserAuthToken } from "../../api/token";

const CardSolicitudes = () => {
  const { user } = useAuth();
  //const url_step_1 = "https://ws.developmentservices.com.mx/Api3.0/ApiLoginRest/api/login/authenticate/";
  const url_step_1 =
    "https://ws.developmentservices.com.mx/Api3.0/ApiLoginRest/api/login/authenticate/";

  //const urlGetLeads = "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/get_all_leads";
  const urlGetLeads =
    "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/leads";

  const [leads, changeLeads] = useState([]);
  const [loadedSol, changeLoad] = useState(true);

  const loadedUrl = window.location.href;
  const urlsplit = loadedUrl.split(PARAMS_PREFIX);
  const userToken = urlsplit[1];
  const meToken = getUserAuthToken();
  const { idleadCTX, changeLeadCTX } = useContext(ContextGlobal);

  const labelDate = (fecha) => {
    if (fecha != undefined || fecha != null) {
      let str = fecha.split("T");
      return (
        <>
          {str[0]}
          <span className="time-list">{str[1]} Hrs</span>
        </>
      );
    }
  };

  const logout = () => {
    /* To do ... nothing*/
  };
  const login = (tokentmp) => {
    setTimeout(function () {
      (async () => {
        const response = await getMeApi(tokentmp, logout);

        //console.log("Token obtenido desde la URL", response);
        let idvendedor = response.IdVend;
        loadLeads(idvendedor);
      })();
    }, 1800);
  };

  useEffect(() => {
    console.log("Valor del token URL", userToken);

    if (userToken == undefined || userToken == null) {
      login(meToken);
    } else {
      login(userToken);
    }
  }, []);

  const loadLeads = (idvendedor) => {
    axios
      .post(url_step_1, { Token: "k2q08aN+zAmcH5/dPMELrQ==" })
      .then(function (response) {
        //console.log("LLamamos a los LEADS :::", response);
        localStorage.setItem("tokenData", JSON.stringify(response.data));
        fetchData(response.data, 1, idvendedor);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchData = (dataToken, page = 1, idvendedor) => {
    const options = { Authorization: "ClickMX " + dataToken.Token };
    //axios.post(urlGetLeads, { Token: "k2q08aN+zAmcH5/dPMELrQ==", Token_Security: "yO316Mqo01tuNTtxp6Nm+37tGGNwACDPvG8lVIRoppQnsx6MOQbxxY4t6RL1tYxU", Params: { iParam1: 0, iParam2: "" } }, {
    axios
      .post(
        urlGetLeads,
        {
          Token: "k2q08aN+zAmcH5/dPMELrQ==",
          TypeProcess: "5",
          Filters: {
            IDPortal: "ABCDEF0123",
            //IDVend: user.IdVend, OLD
            //IDVend: localDataAgent.IdVend, // NEW This Change Correctly?
            IDVend: idvendedor,
            PageRows: page + "|5",
            Visible: 1,
          },
        },
        {
          headers: options,
        }
      )
      .then(function (response) {
        // console.log("Solicitudes => ", response.data);
        let result = response.data;

        localStorage.setItem(
          "notifications",
          JSON.stringify(result.DataResponse)
        );
        if (result.DataResponse == null) {
          changeLeads([]);
        } else changeLeads(result.DataResponse);

        changeLoad(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="card-click z-depth-2 ">
      <div className="row">
        <div className="col m12 s12 text-right">
          <Link
            to="/solicitudes"
            onClick={(e) => {
              changeLeadCTX(null);
            }}
          >
            Ver todas
          </Link>
        </div>
      </div>
      <h5 className="st flex-ico mb-3">
        <span className="material-icons r-ico i-red">notifications_active</span>
        Últimas solicitudes de cotización
      </h5>
      <div className="row scroll-on">
        {loadedSol == true ? (
          <>
            <p className="text-center mt-0 mb-0">
              <strong>Cargando ... </strong>
            </p>
          </>
        ) : (
          <>
            {leads.length == 0 ? (
              <p className="text-center mt-0 mb-0">
                <strong>Sin solicitudes</strong>
              </p>
            ) : (
              <>
                {leads.map((item, index) => {
                  return (
                    // <Link className="" to={`/solicitudes/${item.IDCont}`}>
                    <Link
                      to="/solicitudes"
                      key={index}
                      onClick={(e) => {
                        //e.preventDefault();
                        //console.log(item);
                        changeLeadCTX(item);
                        //return true;
                      }}
                    >
                      <div
                        className="flex-sol pointer over-tr"
                        key={index}
                        onClick={() => {}}
                      >
                        <div className="ico-sol">
                          <span className="material-icons circ-dark i-white">
                            notifications
                          </span>
                        </div>
                        <div className="sol-wrap flex-sol">
                          <div className="txt-sol left-align">
                            <div
                              className="nm-sol"
                              style={{ textTransform: "uppercase" }}
                            >
                              {item.Nombre}
                            </div>
                            <div className="tp-sol">
                              <small>{item.Obs}</small>
                            </div>
                          </div>
                          <div className="date-sol">
                            {/* <span className="bold">29/05/21</span> 17:50 */}
                            <span className="bold">
                              {labelDate(item.FechaCap)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                    // </Link>
                  );
                })}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CardSolicitudes;
