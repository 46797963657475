import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import M from "materialize-css/dist/js/materialize.min.js";
import { updateLead } from "../../api/updateLead";

const Contact = ({
  styleclass,
  dataAgnFb,
  changeFlag,
  isproduction,
  firebaseUser,
  publicToken,
}) => {
  const { user } = useAuth();
  const leadUrlAdd =
    "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/leads";
  const tokenData = localStorage.getItem("tokenData")
    ? JSON.parse(localStorage.getItem("tokenData"))
    : publicToken;
  const clicUser = user.IdVend !== 0 ? user : firebaseUser;

  const [subdomainFB, chSubdomainFB] = useState(dataAgnFb.agent_url);

  const BlockEdit = () => {
    return (
      <div className="edit-frame">
        <button
          className="edit-btn sc-btn"
          onClick={() => {
            changeFlag({ isservice: false, iscard: true, iscedula: false });
          }}
        >
          {" "}
          Editar{" "}
        </button>
      </div>
    );
  };

  const [thanks, changeThanks] = useState({
    form1: "",
    form2: "hidden",
  });

  const [tmpu, changetmpu] = useState({
    name: "",
    phone: "",
    email: "",
    cp: "",
    contactbyphone: 0,
    contactbyemail: 0,
    contactbywhatsapp: 0,
  });

  const [vehicleR, changeVehicleR] = useState({
    unidad: "automovil",
    tipounidad: 1,
    marca: "--",
    modelo: "--",
    transmision: "--",
    version: "--",
    cve_click: "--",
    descripcion: "--",
  });

  const addLead = (tokenData, contactMethod, vehicle) => {
    //console.log("From fetch data", dataToken);
    console.log("Subdomain of firebase", subdomainFB);
    console.log(tokenData);

    // (async () => {
    //   await updateLead(subdomainFB);
    // })();

    const options = { Authorization: "ClickMX " + tokenData };
    let typedata = { concept: "Consulta General", data: vehicle };
    console.log("Enviando informacion A:", contactMethod);
    console.log("Enviando informacion B:", vehicle);
    let car = {
      marca: "ACURA",
      modelo: "2022",
      version: "MDX",
      transmision: "AUT",
      descripcion:
        "AA ACURA MDX ADVANCE 5P V6 3.5L ABS NAVI QC AC AWD AUT. 07 OCUP.",
      cve_click: "AUESACRAMDX0MD0MDX-001",
      idsramo: 1,
    };

    //car.idsramo = 5;

    let contact = { whats: "", email: "", phone: "" };
    contact.whats = contactMethod.contactbywhatsapp;
    contact.email = contactMethod.contactbyemail;
    contact.phone = contactMethod.contactbyphone;

    //Debemos obtener el id del vendedor from firebase
    axios
      .post(
        leadUrlAdd,
        {
          Token: "k2q08aN+zAmcH5/dPMELrQ==",
          TypeProcess: "2",
          Params: {
            IDVend: dataAgnFb.IdVend, //clicUser.IdVend,
            IDPortal: "ABCDEF0123",
            IDSRamo: car.idsramo,
            Cve_Vend: dataAgnFb.Clave, //clicUser.IdCont,
            ApellidoP: "--",
            ApellidoM: "--",
            Nombre: contactMethod.name,
            FechaNac: "01/01/1950",
            Edad: 18,
            RFC: "NOREQUESTED",
            Sexo: 0,
            Email: contactMethod.email,
            Telefono: contactMethod.phone,
            Celular: contactMethod.phone,
            ContWhats: contact.whats,
            ContEmail: contact.email,
            ContTel: contact.phone,
            Obs: "", //JSON.stringify(typedata),
            CPostal: contactMethod.cp, //"00000",
            Modelo: car.modelo,
            Marca: car.marca,
            Version: car.version,
            Transmision: car.transmision,
            Descripcion: car.descripcion,
            Cve_Click: car.cve_click,
            PlanCobertura: "1",
            Detalle: JSON.stringify(typedata),
            /* == */
          },
        },
        {
          headers: options,
        }
      )
      .then(function (response) {
        console.log("Response add Lead => ", response.status, response.data);
        //alert("Hemos recibido tu mensaje correctamente");
        //window.location.reload();
        changeThanks({
          form1: "none",
          form2: "show",
        });
        //changeDataApi(response.data);
        //localStorage.setItem('dataApi', JSON.stringify(response.data));
        //console.log("Local storage", response.data);

        (async () => {
          await updateLead(subdomainFB);
        })();
      })
      .catch(function (error) {
        //alert("Ha ocurrido un problema con el servidor Clic : ", error.message);

        //console.log("Error from FormDatosContacto.js / addLead =>",error.toJSON());
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx

          // window.location.reload();
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          //console.log("Error request =>",error.request);
          const { request } = error;
          //const { XMLHttpRequest} = request; // take everything but 'request'
          //console.log("Objeto Request Error =>", request.status);
          if (request.status === 0) {
            //alert("Ha expirado la sessión ...");
            console.log("Status del REQUEST", request.status);
          }
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error Message =>", error.message);
        }
        console.log("Error CONFIG =>", error.config);
        console.log(error);

        // window.location.reload();
      });
  };

  const emailFormat = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const phoneValidate = (numero) => {
    let nospace = numero.replace(/\s/g, "");
    let nonumbers = nospace.replace(/[^0-9]+/g, "");
    return nonumbers;
  };

  const onSubmit = (e) => {
    //Mandar la información
    //console.log("token from session, form contact ", tokenData);
    e.preventDefault();
    if (tmpu.name == "") {
      M.toast({ html: "Debes completar el campo nombre" });
      return false;
    }
    if (tmpu.email == "") {
      M.toast({ html: "Debes completar el campo email" });
      return false;
    }

    if (tmpu.email == "") {
      M.toast({ html: "Debes completar el campo correo electrónico" });

      return false;
    } else {
      let resultEmail = emailFormat(tmpu.email);
      if (resultEmail == null) {
        M.toast({ html: "Ingresa un correo electrónico válido" });
        return false;
      }
    }

    if (tmpu.phone == "") {
      M.toast({ html: "Debes completar el campo teléfono" });
      return false;
    }

    if (tmpu.cp == "") {
      M.toast({ html: "Debes completar el código postal" });
      return false;
    }
    /*
    
    */

    addLead(tokenData, tmpu, vehicleR);
    //console.log("datos del objeto contactar por",  tmpu);
  };

  const ThanksCard = ({ hasshow = "show" }) => {
    return (
      <div className={"row mb-0 " + hasshow}>
        <div className="col s12 m12">
          <div className="">
            <div className="row mb-0">
              <div className="col s12 m10">
                <h3 className="st mt-0">
                  Pronto me pondré en contacto contigo
                </h3>
                <p>Estos son mis datos, por favor agrégame a tu agenda</p>
              </div>
            </div>

            <form
              onSubmit={(e) => {
                //endForm(e);
                e.preventDefault();
                window.location.reload();
              }}
            >
              <div className="row mt-0 mb-0">
                <div className="col s12 m12 pad-right">
                  <div className="txt-label">Teléfono principal</div>
                  <div className="col m12 s12 valign-wrapper wht mb-0">
                    <i className="material-icons prefix ico-grey">phone</i>
                    <span>
                      {dataAgnFb.agent_phones !== undefined &&
                      dataAgnFb.agent_phones !== null
                        ? dataAgnFb.agent_phones[0].agent_phone
                        : "No registrado"}
                    </span>
                  </div>
                  <div className="clr"></div>
                  <div className="txt-label">WhatsApp</div>
                  <div className="col m12 s12 valign-wrapper wht mb-0">
                    <i className="material-icons prefix ico-grey">chat</i>
                    <span>
                      <span>
                        {dataAgnFb.agent_phones !== undefined &&
                        dataAgnFb.agent_phones !== null
                          ? dataAgnFb.agent_phones[0].agent_phone
                          : "No registrado"}
                      </span>
                    </span>
                  </div>
                  <div className="clr"></div>
                  <div className="txt-label">Mail</div>
                  <div className="col m12 s12 valign-wrapper wht mb-0">
                    <i className="material-icons prefix ico-grey">email</i>
                    <span>
                      {dataAgnFb.agent_email !== undefined &&
                      dataAgnFb.agent_email !== null
                        ? dataAgnFb.agent_email
                        : "No registrado"}
                    </span>
                  </div>
                  <div className="clr"></div>
                  {/* <button className="sc-btn mt-5 full">Guardar en tu agenda</button> */}
                </div>
                <div className="col s12 m12 mt-3 mb-3 text-center">
                  <button
                    className={styleclass.bgPrimary + " cotiza"}
                    type="submit"
                  >
                    Terminar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  // useEffect(() => {
  //   console.log("CUSTOM SITE :: Contact =", dataAgnFb);
  // });

  return (
    <div className="container-fluid white edit">
      <div className="row flex-st" style={{ padding: "42px" }}>
        <ThanksCard hasshow={thanks.form2}></ThanksCard>
        <div className="col m6 s12 forma" style={{ display: thanks.form1 }}>
          <div className="form-tit">Contáctame</div>
          <form action="">
            <label htmlFor="name">Nombre completo</label>
            <input
              className="form-control"
              name="name"
              type="text"
              onChange={(e) => {
                changetmpu({
                  ...tmpu,
                  name: e.target.value,
                });
              }}
              value={tmpu.name}
              placeholder="Tu nombre"
            />
            <label htmlFor="name">Correo electrónico</label>
            <input
              className="form-control"
              name="email"
              type="text"
              onChange={(e) => {
                changetmpu({
                  ...tmpu,
                  email: e.target.value,
                });
              }}
              value={tmpu.email}
              placeholder="Tu correo electrónico"
            />
            <label htmlFor="name">Teléfono celular</label>
            <input
              className="form-control"
              name="phone"
              type="text"
              maxLength={10}
              onChange={(e) => {
                changetmpu({
                  ...tmpu,
                  phone: phoneValidate(e.target.value),
                });
              }}
              value={tmpu.phone}
              placeholder="Tu número celular"
            />
            <label htmlFor="name">Código postal</label>
            <input
              className="form-control"
              name="name"
              type="text"
              maxLength={5}
              onChange={(e) => {
                changetmpu({
                  ...tmpu,
                  cp: phoneValidate(e.target.value),
                });
              }}
              value={tmpu.cp}
              placeholder="Tu código postal"
            />
            <label htmlFor="name">¿Cómo prefieres ser contactado?</label>
            <div>
              <div className="row mt-3">
                <div className="">
                  <div className="col s12 m4">
                    <label>
                      <input
                        name=""
                        type="checkbox"
                        onClick={(e) => {
                          changetmpu({
                            ...tmpu,
                            contactbyphone: e.target.checked ? 1 : 0,
                          });
                          //setContactMethod(tmpu);
                        }}
                      />
                      <span>Teléfono</span>
                    </label>
                  </div>
                  <div className="col s12 m4">
                    <label>
                      <input
                        name=""
                        type="checkbox"
                        onClick={(e) => {
                          changetmpu({
                            ...tmpu,
                            contactbyemail: e.target.checked ? 1 : 0,
                          });
                          //setContactMethod(tmpu);
                        }}
                      />
                      <span>Correo</span>
                    </label>
                  </div>
                  <div className="col s12 m4">
                    <label>
                      <input
                        name=""
                        type="checkbox"
                        onClick={(e) => {
                          changetmpu({
                            ...tmpu,
                            contactbywhatsapp: e.target.checked ? 1 : 0,
                          });
                          //setContactMethod(tmpu);
                        }}
                      />
                      <span>Whatsapp</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <div
                className={styleclass.btn + " btn-blu pointer"}
                onClick={(e) => {
                  onSubmit(e);
                }}
              >
                Contactar
              </div>
            </div>
          </form>
        </div>
        <div
          className="col m6 s12 agent"
          style={
            styleclass.conimage === undefined
              ? { backgroundImage: "url(/images/man.jpg)" }
              : {
                  backgroundImage: `url(${styleclass.conimage})`,
                  minHeight: "480px",
                  backgroundPosition: "top center ",
                }
          }
        >
          {/* Este botón de editar solo aparece cuando se personaliza el sitio */}
          {!isproduction && styleclass.site_is_edited ? <BlockEdit /> : ""}
          <div className="abs-n">
            <div className="nam">
              {styleclass.contitle === undefined
                ? "Mucho gusto soy"
                : styleclass.contitle}
            </div>
            <div className="subname">
              {dataAgnFb.agent_name !== undefined &&
              dataAgnFb.agent_name !== null ? (
                <>{dataAgnFb.agent_name}</>
              ) : (
                ":::"
              )}
              ,{" "}
              {styleclass.condescription === undefined
                ? "su agente de seguros"
                : styleclass.condescription}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
