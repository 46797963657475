import React, { useState, useEffect } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import FormDatosContacto from "./FormDatosContacto";
import FormAgentCard from "./FormAgentCard";

const FormBudgetMedico = ({ formStepContact, changeFSC, changeCotizar, styleclass, dataAgnFb, subdomain, firebaseUser, publicToken }) => {
  const concept = { name: "Seguro Médico", codename: "seguromedico" };
  const [personas, changePersonas] = useState([
    { edad: "", genero: "", show: true },
    { edad: "", genero: "", show: false },
    { edad: "", genero: "", show: false },
  ]);
  const [contactMethod, setContactMethod] = useState({
    name: "",
    email: "",
    phone: "",
    contactby: "",
  });
  const [forms, changeForms] = useState(1);

  useEffect(() => {
    M.AutoInit();
  }, []);

  const FormMain = ({ style, personas, changePersonas }) => {
    const [tmpperson, changeTmpPerson] = useState(personas);

    const updateArray = (value) => {
      const pers = [...tmpperson];
      if (value === "1") {
        pers[0]["show"] = true;
        pers[1]["show"] = false;
        pers[2]["show"] = false;
      }
      if (value === "2") {
        pers[0]["show"] = true;
        pers[1]["show"] = true;
        pers[2]["show"] = false;
      }
      if (value === "3") {
        pers[0]["show"] = true;
        pers[1]["show"] = true;
        pers[2]["show"] = true;
      }

      changeTmpPerson(pers);
    };

    const updateInput = (index, name, value) => {
      const pers = [...tmpperson];
      pers[index][name] = value;
      changeTmpPerson(pers);
    };

    const onSubmitForm = () => {
      if (tmpperson[0].show == true) {
        if (tmpperson[0].edad == "") {
          M.toast({ html: "Ingresa una edad para el Titular" });
          return false;
        }
        if (tmpperson[0].genero == "") {
          M.toast({ html: "Debes seleccionar un género para el Titular" });
          return false;
        }
      }
      if (tmpperson[1].show == true) {
        if (tmpperson[1].edad == "") {
          M.toast({ html: "Ingresa una edad para el asegurado 2" });
          return false;
        }
        if (tmpperson[1].genero == "") {
          M.toast({ html: "Debes seleccionar un género para el asegurado 2" });
          return false;
        }
      }

      if (tmpperson[2].show == true) {
        if (tmpperson[2].edad == "") {
          M.toast({ html: "Ingresa una edad para el asegurado 3" });
          return false;
        }
        if (tmpperson[2].genero == "") {
          M.toast({ html: "Debes seleccionar un género para el asegurado 3" });
          return false;
        }
      }
      changePersonas(tmpperson);
      changeForms(2);
    };

    const setNumber = (numero) => {
      let nospace = numero.replace(/\s/g, "");
      let nonumbers = nospace.replace(/[^0-9]+/g, "");
      return nonumbers;
    };

    useEffect(() => {
      console.log("personas TMP", tmpperson);
    });

    //VALIDACIONES
    return (
      <div className={`row ${style}`}>
        <div className="col s12 m12">
          <div className="card radius-card no-padding">
            <div className="card-content no-padding ">
              <div className="row">
                <div className="col s12 m12">
                  <div className="card radius-card mt-0 p-3">
                    <h3 className="st">Proporciona la siguiente información</h3>
                    <p>Esta información es necesaria para poder ofrecerte una cotización a tu medida</p>
                  </div>
                </div>
                <div className="col s12 m12">
                  <div className="col s12 m4 mt-4">
                    <label htmlFor="m_personas">¿Cuantas personas desea asegurar?</label>
                    <select
                      name="nopersonas"
                      id="nopersonas"
                      className="browser-default"
                      onChange={(e) => {
                        //console.log(e.target.value);
                        updateArray(e.target.value);
                      }}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </div>
                </div>
                <div className="col s12 m12">
                  <div className="">
                    {tmpperson.map((item, index) => {
                      return (
                        <div className={`row ${item.show ? "show" : "hidden"}`} key={index}>
                          <div className=" col s12 m12 mt-5">
                            <h4>
                              Asegurado {index + 1} {index === 0 ? "Títular" : ""}
                            </h4>
                            <div className="col s12 m4">
                              <label>Edad</label>
                              <input
                                className="rounded-input"
                                type="text"
                                onChange={(e) => {
                                  updateInput(index, "edad", setNumber(e.target.value));
                                }}
                                value={item.edad}
                                maxLength={2}
                              />
                            </div>
                            <div className="col s12 m4">
                              <label>Género</label>
                              <select
                                className="browser-default"
                                type="text"
                                onChange={(e) => {
                                  updateInput(index, "genero", e.target.value);
                                }}
                                value={item.genero}
                              >
                                <option value="" disabled={true}>
                                  Seleccionar
                                </option>
                                <option value="masculino">Masculino</option>
                                <option value="femenino">Femenino</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col s12 m12 text-center pt-4 pb-4">
                  <button
                    className={styleclass.bgPrimary + " cotiza"}
                    onClick={() => {
                      onSubmitForm();
                    }}
                  >
                    Enviar información
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const FormRes = ({ style, changeForms, personas }) => {
    return (
      <div className={`row ${style}`}>
        <div className="col s12 m12 ">
          <div className="card radius-card p-3">
            <h3 className="st">
              Estas cotizando{" "}
              <button
                className="btn-borblub"
                onClick={() => {
                  changeForms(1);
                }}
              >
                Editar información
              </button>
            </h3>
            <div>
              {personas.map((item, index) => {
                return (
                  <div key={index} className={` ${item.show ? "show" : "hidden"}`}>
                    <span>{index + 1}</span> |{" "}
                    <span>
                      <b>Edad:</b> {item.edad}
                    </span>{" "}
                    |{" "}
                    <span>
                      <b>Genero:</b> {item.genero}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <FormMain style={forms === 1 ? "show" : "hidden"} personas={personas} changePersonas={changePersonas} changeForms={changeForms} />
      <FormRes style={forms === 2 ? "show" : "hidden"} personas={personas} changePersonas={changePersonas} changeForms={changeForms} />
      <FormDatosContacto
        style={forms === 2 ? "show" : "hidden"}
        contactMethod={contactMethod}
        setContactMethod={setContactMethod}
        changeForms={changeForms}
        data={personas}
        concept={concept}
        styleclass={styleclass}
        subdomain={subdomain}
        firebaseUser={firebaseUser}
        publicToken={publicToken}
        dataAgnFb={dataAgnFb}
      />
      <FormAgentCard
        style={forms === 3 ? "show" : "hidden"}
        changeFSC={changeFSC}
        changeForms={changeForms}
        changeCotizar={changeCotizar}
        formStepContact={formStepContact}
        styleclass={styleclass}
        dataAgnFb={dataAgnFb}
      />
    </>
  );
};

export default FormBudgetMedico;
