import React, { useState, useContext, useEffect } from "react";
import db from "../../firebase/firebaseConfig";
import { doc, updateDoc, onSnapshot, collection, query, where, getDocs } from "firebase/firestore";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import axios from "axios";
import { URL_CONSULTA_SERVICIOS } from "../../utils/constants";

export default function Catalog() {
  useEffect(() => {
    const q = query(collection(db, "cp_agents"), where("IdVend", "==", 9304));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.empty) {
        console.log(querySnapshot);
      } else {
        const dataFirebase = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data(), time: doc.data().createTime }));
        console.log(dataFirebase);
      }
    });
  }, []);
  return (
    <>
      <div>aldo</div>
    </>
  );
}
