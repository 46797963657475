import React from "react";

export default function CanceledPremium() {
  return (
    <>
      <div id="modal-canceled-premium" className="modal">
        <div className="modal-content">
          <div className="flex-center-alw"></div>
          <a href="#" className="modal-close top-left">
            Cerrar
          </a>
          <h5 className="st text-center">Cancelación Click+ Premium</h5>
          <div className="modal-header">
            <img className="full-img" src="/images/back-diamond.jpg" alt="" />
          </div>
          <div className="row mid-pad">
            <h5 className="st text-center">Te vamos a extrañar</h5>
            <p className="small-txt text-center mt-3">Lamentamos que dejes tu membresía pero puedes regresar en cualquier momento</p>

            <div className="mt-5 text-center ">
              <button className="waves-effect sq-btn darken-1 white-text modal-close">Continuar</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
