import React, { useEffect, useState } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import storage from "../../firebase/firebaseConfigStorage";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { getAllByPlaceholderText } from "@testing-library/dom";
import { getCompaniesApi } from "../../api/Admin/companies";
import { setToken, getToken, removeToken, setUserAuthToken, getUserAuthToken } from "../../api/token";
//Call the firebase setup ¿?
//import db from '../../firebase/firebaseConfig';
//import { doc, updateDoc } from "firebase/firestore";

const MenuPortal = ({
  //changeClassStyle, styleclass, dataAgnFb, products, changeProducts, brandsList, setBrandsList
  styleclass,
  changeClassStyle,
  dataAgnFb,
  products,
  changeProducts,
  brandsList,
  setBrandsList,
  chFlag,
}) => {
  const [auth, setAuth] = useState(undefined);
  const collapse = document.querySelectorAll(".collapsible");

  const { brands } = styleclass;

  const [brandsFB, changeBrandsLocal] = useState([]);
  const { user } = useAuth();

  const logout = () => {
    if (auth) {
      removeToken();
      setAuth(null);
      window.location.href = "https://clicksegurosvip.com";
    }
  };




  const getBrandsAgain = () => {
    (async () => {
      const response = await getCompaniesApi(logout);
      changeClassStyle({
        ...styleclass,
        brands: (response?.DataResponse || [])
      });
      changeBrandsLocal(response?.DataResponse || []);
    })();
  };

  const changeFont = (e) => {
    changeClassStyle({
      ...styleclass,
      fonttype: e.target.value,
    });
  };

  //#modal-premium

  //Handle Events
  const chBrand = (e, id) => {
    //console.log("item id",id);
    const tmp = brandsList.map((br) => {
      if (br.IDCia === id) {
        return { ...br, active: e.target.checked ? 1 : 0 };
      }
      return br;
    });

    setBrandsList(tmp);
    changeClassStyle({
      ...styleclass,
      brands: tmp,
    });
    changeBrandsLocal(tmp);
  };


  const validateCheck = (e, id) => {
    changeClassStyle({
      ...styleclass,
      show_logotipo: e.target.checked ? true : false,
    });
  };

  const grid = 8;
  //A little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    console.log(result);

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    //width: 250
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const localitems = reorder(products, result.source.index, result.destination.index);
    //changeDataList(localitems); //last Array
    changeProducts(localitems);
    changeClassStyle({
      ...styleclass,
      products: localitems,
    });
  };

  /* files changes */
  const allInputs = { imgUrl: "" };
  const [imageAsFile, setImageAsFile] = useState("");
  const [blockButton, chBlock] = useState(false);

  //console.log(imageAsFile)
  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    setImageAsFile((imageFile) => image);
  };
  const handleFireBaseUpload = (e) => {
    chBlock(!blockButton);
    e.preventDefault();
    console.log("start of upload");

    if (imageAsFile === "") {
      //console.error(`not an image, the image file is a ${typeof (imageAsFile)}`);
      alert("Debes seleccionar un archivo");
      chBlock(false);
      return;
    }
    console.log(storage);
    //Eliminar antes de subir ...
    const imageRef = ref(storage, "imageslogo/" + imageAsFile.name);
    //const imageRef = ref(storage, 'imageslogo/' + uuidv4());
    uploadBytesResumable(imageRef, imageAsFile)
      .then((snapshot) => {
        console.log("Uploaded", snapshot.totalBytes, "bytes.");
        console.log("File metadata:", snapshot.metadata);
        // Let's get a download URL for the file.
        getDownloadURL(snapshot.ref).then((url) => {
          console.log("File available at", url);
          // ...
          changeClassStyle({
            ...styleclass,
            urlimage: url,
          });
        });
        setImageAsFile(""); //clear the input file
        chBlock(false);
      })
      .catch((error) => {
        console.error("Upload failed", error);
        // ...
      });
  };

  const changeMenuWeb = (position) => {
    console.log("Se ha seleccionado el menu =>", position);
    changeClassStyle({
      ...styleclass,
      menu_side: position,
    });
  };

  const hasPremium = (stylecolor) => {
    //console.log("El usuario es premium en el tema",user.IsPremium);
    if (user.IsPremium == "Activo") {
      changeClassStyle({
        ...styleclass,
        bgPrimary: stylecolor.bgPrimary,
        bgSecondary: stylecolor.bgSecondary,
        bgBox: stylecolor.bgBox,
        btn: stylecolor.btn,
      });
    } else {
      // return modal
      const showcard = document.getElementById("modal-premium");
      const modal = M.Modal.init(showcard, {});
      modal.open();
      //return false;
    }
  };

  const hasPremiumFont = (e) => {
    let tmpval = e.target.value;
    console.log("Valor de la fuente => ", tmpval);
    if (tmpval == "nunito" || tmpval == "montserrat" || tmpval == "roboto" || tmpval == "source") {
      if (user.IsPremium == "Activo") {
        //changeFont({ ...styleclass, fonttype: e.target.value });
        changeClassStyle({
          ...styleclass,
          fonttype: e.target.value,
        });
      } else {
        const showcard = document.getElementById("modal-premium");
        const modal = M.Modal.init(showcard, {});
        modal.open();
      }
    } else {
      changeClassStyle({
        ...styleclass,
        fonttype: e.target.value,
      });
    }
  };

  useEffect(() => {
    chFlag(true); //llamamos al método que cambia la bandera o sea los cambios del menu
  });

  useEffect(() => {
    M.AutoInit();
    M.Collapsible.init(collapse, {});
    //console.log("From Menu Portal =>",dataAgnFb);
  }, []);

  useEffect(() => {
    let { user_site } = dataAgnFb;
    if (user_site != undefined) {
      if (user_site.brands != undefined) {
        changeBrandsLocal(user_site.brands);
      }
    }
  }, []);



  const wasEditedText = (styleText) => {

    if (styleText.block_title != "Aquí puede encontrar los principales ramos que trabajo") return true;
    if (styleText.block_description != "Si no encuentras el seguro que necesitas, contáctame dando click en el botón de abajo") return true;
    if (styleText.contratame != "Contratar el seguro a tu medida es muy sencillo conmigo. Contáctame y te ayudaré a encontrar la mejor opción para ti") return true;
    if (styleText.contitle != "Mucho gusto soy") return true;
    if (styleText.condescription != "tu agente de seguros") return true;

    return false;
  }

  //block_title: "Aquí puede encontrar los principales ramos que trabajo",  
  const LogotipoView = () => {
    //console.log("Texto predetermindado",styleclass.block_title);
    if (wasEditedText(styleclass)) {
      return (
        <>
          <span>Click Seguros, no puede ser utilizado ya que estás editando el contenido autorizado</span>
        </>
      );
    } else {
      return (
        <label>
          Mostrar logotipo Click
          <input type="checkbox" checked={styleclass.show_logotipo ? "checked" : ""} onChange={(e) => validateCheck(e)} />
          <span className="lever"></span>
        </label>
      );
    }
  };

  return (
    <div>
      <h4 className=" pad-s">
        <NavLink to="/">
          <span className="ico-circ wh icon-left"></span>
          Regresar al inicio
        </NavLink>
      </h4>
      <ul className="collapsible no-shadow">
        <li>
          <div className="collapsible-header">Apariencia del sitio</div>
          <div className="collapsible-body">
            <div className="menu-pad">
              <div className="flex-ic box-bl z-depth-2">
                <div className="b-ico">
                  <img className="full-img" src="images/p-left.svg" alt="" />
                </div>
                <button
                  className="b-txt"
                  onClick={() => {
                    changeMenuWeb("vertical");
                  }}
                >
                  Menu lateral
                </button>
              </div>
              <div className="flex-ic box-bl z-depth-2 mt-2">
                <div className="b-ico">
                  <img className="full-img" src="images/p-top.svg" alt="" />
                </div>
                <button
                  className="b-txt"
                  onClick={() => {
                    changeMenuWeb("horizontal");
                  }}
                >
                  Menu superior
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="collapsible-header">Logotipo</div>
          <div className="collapsible-body">
            <div className="menu-pad">
              <div className="switch mb-4">
                <LogotipoView></LogotipoView>
              </div>
              <div className="b-white">
                <div className="camera">
                  <div className="im-cam">
                    <img className="full-img" src="images/cam.svg" alt="" />
                  </div>
                </div>
                <p className="stxt mt-2">Formato JPG o PNG menor a 5MB</p>
                <div className="b-alert mt-3 pad-m ">
                  <span className="c-blue icon-infob"></span>Te recomendamos que la imagen sea en formato PNG con fondo transparente.
                </div>
                {/* <div className="gh-btn mt-3">Subir foto</div> */}
                <form onSubmit={handleFireBaseUpload}>
                  <div className="file-field input-field pad-m ">
                    <div className="btn fil">
                      <span>Imagen</span>
                      <input type="file" onChange={handleImageAsFile} />
                    </div>
                    <div className="file-path-wrapper">
                      <input className="file-path validate" type="text" placeholder="Sube una imagen" />
                    </div>
                  </div>
                  <div className="center-align mb-2">
                    <button className="gh-btn normal-text" disabled={blockButton}>
                      Subir imagen
                    </button>
                  </div>
                  <p>{blockButton ? "Espere un momento por favor" : ""}</p>
                </form>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="collapsible-header">Tema de colores</div>
          <div className="collapsible-body">
            <div className="menu-pad">
              <div
                className="b-white mt-10 btn-theme"
                onClick={() => {
                  changeClassStyle({
                    ...styleclass,
                    bgPrimary: "back-bl",
                    bgSecondary: "sec-blue",
                    bgBox: "back-lbl",
                    btn: "",
                  });
                }}
              >
                <div className="flex-ic">
                  <div className="sq-color prim-a"></div>
                  <div className="sq-color prim-b"></div>
                  <div className="sq-color sec-a"></div>
                  <div className="sq-color sec-b"></div>
                  <div className="stxt ml">Amigable</div>
                </div>
              </div>
              <div
                className="b-white mt-10 btn-theme"
                onClick={() => {
                  changeClassStyle({
                    ...styleclass,
                    bgPrimary: "back-bl-ene",
                    bgSecondary: "sec-ene",
                    bgBox: "back-lbl-ene",
                    btn: "btn-energetic",
                  });
                }}
              >
                <div className="flex-ic">
                  <div className="sq-color prim-a-ener"></div>
                  <div className="sq-color prim-b-ener"></div>
                  <div className="sq-color sec-a-ener"></div>
                  <div className="sq-color sec-b-ener"></div>
                  <div className="stxt ml">Energético</div>
                </div>
              </div>
              <div
                className="b-white mt-10 btn-theme"
                onClick={() => {
                  changeClassStyle({
                    ...styleclass,
                    bgPrimary: "back-bl-dest",
                    bgSecondary: "sec-dest",
                    bgBox: "back-lbl-dest",
                    btn: "btn-dest",
                  });
                }}
              >
                <div className="flex-ic">
                  <div className="sq-color prim-a-dest"></div>
                  <div className="sq-color prim-b-dest"></div>
                  <div className="sq-color sec-a-dest"></div>
                  <div className="sq-color sec-b-dest"></div>
                  <div className="stxt ml">Destacado</div>
                </div>
              </div>
              <div
                className="b-white mt-10 btn-theme"
                onClick={() => {
                  changeClassStyle({
                    ...styleclass,
                    bgPrimary: "back-bl-vd",
                    bgSecondary: "sec-vd",
                    bgBox: "back-lbl-vd",
                    btn: "btn-vd",
                  });
                }}
              >
                <div className="flex-ic">
                  <div className="sq-color prim-a-vd"></div>
                  <div className="sq-color prim-b-vd"></div>
                  <div className="sq-color sec-a-vd"></div>
                  <div className="sq-color sec-b-vd"></div>
                  <div className="stxt ml">Verde</div>
                </div>
              </div>
              <div className="for-premium">
                <div className="flex-alw mb-3">
                  <div className="mini-tit-premium">Temas premium</div>
                  <img className="ico-pr-right" src="/images/diamondb.svg" alt="" />
                </div>
                <div>Desbloquea más temas al suscribirte a nuestro plan premium</div>
                <button className="mt-3 btn-edit-ci-lit modal-trigger" href="#modal-premium">
                  Suscríbete
                </button>
              </div>

              {/* START is premium */}

              <div
                className="b-white mt-10 btn-theme"
                onClick={() => {
                  /*
                  changeClassStyle({
                    ...styleclass,
                    bgPrimary: "back-bl-mini",
                    bgSecondary: "sec-mini",
                    bgBox: "back-lbl-mini",
                    btn: "btn-mini",
                  });
                  */
                  let stylecolor = {
                    bgPrimary: "back-bl-mini",
                    bgSecondary: "sec-mini",
                    bgBox: "back-lbl-mini",
                    btn: "btn-mini",
                  };
                  hasPremium(stylecolor);
                }}
              >
                <div className="flex-ic">
                  <div className="sq-color prim-a-mini"></div>
                  <div className="sq-color prim-b-mini"></div>
                  <div className="sq-color sec-a-mini"></div>
                  <div className="sq-color sec-b-mini"></div>
                  <div className="stxt ml">Minimalista</div>
                </div>
              </div>

              <div
                className="b-white mt-10 btn-theme"
                onClick={() => {
                  // changeClassStyle({
                  //   ...styleclass,
                  //   bgPrimary: "back-bl-rd",
                  //   bgSecondary: "sec-rd",
                  //   bgBox: "back-lbl-rd",
                  //   btn: "btn-rd",
                  // });
                  let stylecolor = {
                    bgPrimary: "back-bl-rd",
                    bgSecondary: "sec-rd",
                    bgBox: "back-lbl-rd",
                    btn: "btn-rd",
                  };
                  hasPremium(stylecolor);
                }}
              >
                <div className="flex-ic">
                  <div className="sq-color prim-a-rd"></div>
                  <div className="sq-color prim-b-rd"></div>
                  <div className="sq-color sec-a-rd"></div>
                  <div className="sq-color sec-b-rd"></div>
                  <div className="stxt ml">Rojo</div>
                </div>
              </div>

              <div
                className="b-white mt-10 btn-theme"
                onClick={() => {
                  // changeClassStyle({
                  //   ...styleclass,
                  //   bgPrimary: "back-bl-ele",
                  //   bgSecondary: "sec-ele",
                  //   bgBox: "back-lbl-ele",
                  //   btn: "btn-elegant",
                  // });

                  let stylecolor = {
                    bgPrimary: "back-bl-ele",
                    bgSecondary: "sec-ele",
                    bgBox: "back-lbl-ele",
                    btn: "btn-elegantt",
                  };
                  hasPremium(stylecolor);
                }}
              >
                <div className="flex-ic">
                  <div className="sq-color prim-a-ele"></div>
                  <div className="sq-color prim-b-ele"></div>
                  <div className="sq-color sec-a-ele"></div>
                  <div className="sq-color sec-b-ele"></div>
                  <div className="stxt ml">Elegante</div>
                </div>
              </div>

              <div
                className="b-white mt-10 btn-theme"
                onClick={() => {
                  // changeClassStyle({
                  //   ...styleclass,
                  //   bgPrimary: "back-bl-sbr",
                  //   bgSecondary: "sec-sbr",
                  //   bgBox: "back-lbl-sbr",
                  //   btn: "btn-sbr",
                  // });

                  let stylecolor = {
                    bgPrimary: "back-bl-sbr",
                    bgSecondary: "sec-sbr",
                    bgBox: "back-lbl-sbr",
                    btn: "btn-sbr",
                  };
                  hasPremium(stylecolor);
                }}
              >
                <div className="flex-ic">
                  <div className="sq-color prim-a-sbr"></div>
                  <div className="sq-color prim-b-sbr"></div>
                  <div className="sq-color sec-a-sbr"></div>
                  <div className="sq-color sec-b-sbr"></div>
                  <div className="stxt ml">Sobrio</div>
                </div>
              </div>

              {/* END is premium */}
            </div>
          </div>
        </li>
        <li>
          <div className="collapsible-header">Tipo de letra</div>
          <div className="collapsible-body">
            <div className="menu-pad">
              <div className="b-white">
                <div className="flex-ic">
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      //changeFont(e);
                      hasPremiumFont(e);
                    }}
                  >
                    <optgroup label="Free">
                      <option value="inherit">Open Sans</option>
                      <option value="ibm">IBM Plex Sans</option>
                      <option value="fira">Fira Sans</option>
                    </optgroup>
                    <optgroup label="Premium">
                      <option value="nunito">Nunito</option>
                      <option value="montserrat">Montserrat</option>
                      <option value="roboto">Roboto Slab</option>
                      <option value="source">Source Sans Pro</option>
                    </optgroup>
                  </select>
                  <div className="stxt ml">Opciones</div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="collapsible-header">Ordena tus productos</div>
          <div className="collapsible-body">
            <div className="b-alert menu-pad">
              <span className="c-blue icon-infob"></span>Organiza el orden en que se mostrarán los productos, da click en ellos y manténlo presionado para arrastrarlos en el orden que deseas. Si
              deseas modificar o quitar alguno, visita la sección de mis productos
            </div>
            <div className="menu-pad">
              <div className="mt-2">
                <div className="m-tit">Orden de aparición</div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div className="drag-container" {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        {products.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                              <>
                                <div className="flex">
                                  <span className="drag-num">{index + 1}</span>
                                  <div
                                    className={(item.status === 1 ? "active-srv" : "inactive-srv") + " drag-item"}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                  >
                                    {item.name}
                                  </div>
                                </div>
                              </>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="collapsible-header">Aseguradoras</div>
          <div className="collapsible-body">
            <div className="b-alert menu-pad">
              <span className="c-blue icon-infob"></span>Selecciona las aseguradoras que deseas que se muestren en tu sitio público
            </div>
            <div className="menu-pad">
              {(brandsFB.length > 0) ?
                brandsFB.filter((item) => item.Status == true).map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="switch mb-1">
                        <label>
                          {item.Nombre}
                          <input type="checkbox" checked={item.active === 1 ? "checked" : ""} onChange={(e) => chBrand(e, item.IDCia)} />
                          <span className="lever"></span>
                        </label>
                      </div>
                    </div>
                  );
                })
                :
                <div className="text-center">
                  <span className="material-icons red-text">warning</span>
                  <br />
                  Error al solicitar las aseguradoras, da clic en actualizar para realizar la solicitud.
                  <br />
                  <br />
                  <span className="pointer chip btn-small orange white-text" onClick={getBrandsAgain} >Actualizar</span>
                </div>
              }

            </div>
          </div>
        </li>
        <li>
          <div className="collapsible-header">Datos de contacto</div>
          <div className="collapsible-body">
            <div className="menu-pad">
              <div className="row">
                {dataAgnFb.agent_url !== "undefined" && dataAgnFb.agent_url !== null ? (
                  <>
                    <div className="col m12 s12 valign-wrapper wht">
                      <div className="elip flex-alw">
                        <i className="material-icons prefix ico-grey">language</i>
                        <span>{dataAgnFb.agent_url}</span>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {dataAgnFb.agent_name !== "undefined" && dataAgnFb.agent_name !== null ? (
                  <>
                    <div className="col m12 s12 valign-wrapper wht">
                      <div className="elip flex-alw">
                        <i className="material-icons prefix ico-grey">account_circle</i>
                        <span>{dataAgnFb.agent_name}</span>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {dataAgnFb.agent_email !== "undefined" && dataAgnFb.agent_email !== null ? (
                  <>
                    <div className="col m12 s12 valign-wrapper wht">
                      <div className="elip flex-alw">
                        <i className="material-icons prefix ico-grey">mail_outline</i>
                        <span>{dataAgnFb.agent_email}</span>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
/*
const [dataList, changeDataList] = useState([
    {
        id: "item-1",
        content: "producto 1"
    },
    {
        id: "item-2",
        content: "producto 2"
    },
    {
        id: "item-3",
        content: "producto 3"
    },
    {
        id: "item-4",
        content: "producto 4"
    },
    {
        id: "item-5",
        content: "producto 5"
    },
]);
*/
export default MenuPortal;
