import React, { useState } from "react";

const MenuCedula = ({ styleclass, changeClassStyle, changeFlag }) => {
  const [inputContratame, changeInputCon] = useState(styleclass.contratame === undefined ? "" : styleclass.contratame);
  const [inputCedula, changeInputCed] = useState(styleclass.cedula === undefined ? "" : styleclass.cedula);
  const handleInputCon = (e) => {
    changeInputCon(e.target.value);
  };
  const handleInputCed = (e) => {
    changeInputCed(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    changeClassStyle({
      ...styleclass,
      contratame: inputContratame,
      cedula: inputCedula,
    });
  };
  return (
    <div className="section">
      <button className="link-back" onClick={() => changeFlag({ isservice: false, iscard: false, iscedula: false })}>
        <h4 className="pad-s">
          <i className="ico-ftl ico-circ wh icon-facebook">&#xf104;</i>
          Regresar al configurador
        </h4>
      </button>

      <div className="b-alert menu-pad">
        <div className="flex-alw">
          <span className="c-blue icon-infob"></span>
          <div className="pad-l1">Pesonaliza los textos que apareceran en tu página, te recomendamos utilizar textos cortos. Si quieres editar la oferta que estas ofrecion visita mis productos</div>
        </div>
      </div>
      <div className="menu-pad">
        <p className="mb-3">Este texto se mostrará a tus clientes </p>
        <div className="row border mb-4">
          <form onSubmit={handleSubmit}>
            <div className="input-field col m12 s12">
              <label className="active">Descripción</label>
              <textarea
                className="textarea-serv"
                type="text"
                value={inputContratame}
                onChange={(e) => {
                  handleInputCon(e);
                }}
              />
            </div>
            <div className="input-field col m12 s12">
              <label className="active">Cedula</label>
              <input
                type="text"
                className=""
                value={inputCedula}
                onChange={(e) => {
                  handleInputCed(e);
                }}
              />
            </div>
            <div className="col m12 s12 center">
              <button type="submit" className="btn btn-edit-srv normal-text">
                Actualizar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MenuCedula;
